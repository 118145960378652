import React from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Box,
  ButtonBase,
} from '@mui/material';
import Data from '../Data';
import { useNavigate } from 'react-router-dom';

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

function completeRentalsWithOthers(existingRentals, ownerId) {
  // Start with the existing rentals from the same owner, up to 4
  let selectedRentals = existingRentals.slice(0, 4);

  if (selectedRentals.length < 4) {
    // Create a list of all possible rentals excluding those from the existing owner
    let allOtherRentals = [];
    Data.forEach(item => {
      if (item.owner.id !== ownerId) {
        allOtherRentals.push(...item.owner.rentals);
      }
    });

    // Shuffle the list of all other rentals to randomize selection
    allOtherRentals.sort(() => Math.random() - 0.5);

    // Select additional rentals to fill up to 4, ensuring one rental per owner
    let additionalRentals = [];
    let ownerIds = new Set(selectedRentals.map(rental => rental.ownerId));
    for (let rental of allOtherRentals) {
      if (additionalRentals.length + selectedRentals.length >= 4) break;
      if (!ownerIds.has(rental.ownerId)) {
        additionalRentals.push(rental);
        ownerIds.add(rental.ownerId);
      }
    }

    // Combine the original list with the additional selections
    selectedRentals = selectedRentals.concat(additionalRentals);
  }

  return selectedRentals;
}


const Others = ({ otherOwnerRentals, ownerId}) => {
  const styles = {
    listText: {
      margin: '0 .5rem',
      wordBreak: 'break-word',
    },
    title: {
      fontWeight: 'bold',
      color: '#384D66',
      marginTop: '30px',
      marginBottom: '10px',
    },
  };

  let items = completeRentalsWithOthers(otherOwnerRentals, ownerId);

  const navigate = useNavigate();
  function handleClick(id) {
    navigate('/rental/' + id);
  }

  return (
    <Box sx={{ boxShadow: 3, margin: '.5rem 1rem' }}>
      <Typography
        style={styles.title}
        sx={{ margin: '1rem' }}
        variant='h4'
        gutterBottom
      >
        Autres logements
      </Typography>
      <List>
        {items.map((item, idx) => (
          <ButtonBase
            key={idx}
            onClick={(e) => handleClick(item.id)}
            sx={{ ':hover': { boxShadow: 20 } }}
          >
            <ListItem key={item.id}>
              <ListItemAvatar>
                <Avatar
                  alt={`Item ${item.id}`}
                  src={item.imageUrl}
                  sx={{ width: 150, height: 150, borderRadius: '0' }} // Apply square styling
                />
              </ListItemAvatar>
              <ListItemText style={styles.listText} primary={item.name} />
            </ListItem>
          </ButtonBase>
        ))}
      </List>
    </Box>
  );
};

export default Others;
