/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Container, Paper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Yes from '@mui/icons-material/Check';
import No from '@mui/icons-material/Close';

function Description({ data }) {
  // Use these constants to conditionally render or apply styles based on the screen size.
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.between('sm', 'md')
  );

  const styles = {
    descrTitle: {
      fontWeight: 'bold',
      color: '#384D66',
      marginTop: '30px',
      marginBottom: '10px',
      textAlign: 'center',
    },
    color: {
      color: '#384D66',
    },
    descrParagraph: {
      margin: '0.5rem 1rem',
    },
    pointTitle: {
      fontSize: isSmallScreen ? '12px' : isMediumScreen ? '14px' : '18px',
      fontWeight: 'bold',
    },
    pointDescr: {
      fontSize: isSmallScreen ? '10px' : isMediumScreen ? '12px' : '16px',
    },
  };

  const getRightIcon = (value) =>
    value ? (
      <Yes color='primary' style={styles.color} />
    ) : (
      <No color='primary' style={styles.color} />
    );

  return (
    <section id='Description' style={styles.descrSection}>
      <Container maxWidth='md'>
        <Paper
          elevation={3}
          style={{
            padding: '16px',
            margin: '16px 0',
            display: 'flex',
            justifyContent: 'space-around',
            textAlign: 'center',
          }}
        >
          {/* First Section: One Word */}
          <Typography variant='p' gutterBottom>
            <span style={styles.descrTitle}>Personne(s)</span>: {data.capacity}
          </Typography>

          <Typography variant='p' gutterBottom>
            <span style={styles.descrTitle}>Lit(s)</span>: {data.beds}
          </Typography>

          <Typography variant='p' gutterBottom>
            <span style={styles.descrTitle}>Piece(s)</span>: {data.rooms}
          </Typography>

          <Typography variant='p' gutterBottom>
            <span style={styles.descrTitle}>Type</span>: {data.type}
          </Typography>
        </Paper>

        <Paper
          elevation={3}
          style={{
            padding: '16px',
            margin: '16px 0',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          {/* First Section: One Word */}
          <Typography variant='p' gutterBottom>
            <span style={styles.descrTitle}>Wifi</span>:
            {getRightIcon(data.wifi)}
          </Typography>

          <Typography variant='p' gutterBottom>
            <span style={styles.descrTitle}>Cafetière</span>:
            {getRightIcon(data.coffee)}
          </Typography>

          <Typography variant='p' gutterBottom>
            <span style={styles.descrTitle}>Parking</span>:
            {getRightIcon(data.parking)}
          </Typography>
        </Paper>

        <Paper elevation={3} style={{ padding: '16px', margin: '16px 0' }}>
          <Typography sx={styles.descrTitle} variant='h4'>
            {data.name}
          </Typography>

          <Typography sx={{ color: '#384D66' }} variant='h6' gutterBottom>
            Transport
          </Typography>
          <Typography variant='body1'>{data.transports}</Typography>

          <Typography sx={{ color: '#384D66' }} variant='h6' gutterBottom>
            Point d'intérêts
          </Typography>
          <Typography variant='body1'>{data.interest}</Typography>

          <Typography sx={{ color: '#384D66' }} variant='h6' gutterBottom>
            Description
          </Typography>
          <Typography variant='body1'>{data.description}</Typography>
        </Paper>
      </Container>
    </section>
  );
}

export default Description;
