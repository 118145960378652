import React from 'react';

import Home from '../components/Home';
import Contact from '../components/Contact';
import FeaturedRentals from '../components/FeaturedRentals';

function HomePage() {
  return (
    <>
      <Home />
      <FeaturedRentals />
      <Contact />
    </>
  );
}

export default HomePage;
