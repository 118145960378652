import React from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
} from '@mui/material';

const styles = {
  applyButton: {
    color: 'white',
    backgroundColor: '#384D66',
  },
};

const Filter = ({
  searchTerm,
  setSearchTerm,
  filters,
  setFilters,
  applyFilters,
}) => {
  const handleChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.checked,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    applyFilters();
  };

  return (
    <form onSubmit={onSubmit} style={{ width: '90%', margin: 'auto' }}>
      <TextField
        label='Recherche'
        variant='outlined'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
        margin='normal'
      />
      <FormControl fullWidth variant='outlined' margin='normal'>
        <InputLabel>Type de logement</InputLabel>
        <Select
          value={filters.type}
          onChange={handleChange}
          label='Type de logement'
          name='type'
        >
          <MenuItem value=''>
            <em>Néant</em>
          </MenuItem>
          <MenuItem value='Apartment'>Appartement</MenuItem>
          <MenuItem value='House'>Maison</MenuItem>
          <MenuItem value='Duplex'>Duplex</MenuItem>
          <MenuItem value='Studio'>Studio</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth variant='outlined' margin='normal'>
        <InputLabel>Capacité d'accueil</InputLabel>
        <Select
          value={filters.capacity}
          onChange={handleChange}
          label="Capacité d'accueil"
          name='capacity'
        >
          <MenuItem value=''>
            <em>Néant</em>
          </MenuItem>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
            <MenuItem value={num} key={num}>
              {num}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant='outlined' margin='normal'>
        <InputLabel>Nombre de pièces</InputLabel>
        <Select
          value={filters.nb_rooms}
          onChange={handleChange}
          label='Nombre de pièces'
          name='nb_rooms'
        >
          <MenuItem value=''>
            <em>Néant</em>
          </MenuItem>
          {[1, 2, 3, 4, 5].map((num) => (
            <MenuItem value={num} key={num}>
              {num}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant='outlined' margin='normal'>
        <InputLabel>Nombre de lits</InputLabel>
        <Select
          value={filters.nb_beds}
          onChange={handleChange}
          label='Nombre de lits'
          name='nb_beds'
        >
          <MenuItem value=''>
            <em>Néant</em>
          </MenuItem>
          {[1, 2, 3, 4, 5].map((num) => (
            <MenuItem value={num} key={num}>
              {num}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.has_wifi}
              onChange={handleCheckChange}
              name='has_wifi'
            />
          }
          label='Wi-Fi'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.has_coffee}
              onChange={handleCheckChange}
              name='has_coffee'
            />
          }
          label='Cafetière'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.has_parking}
              onChange={handleCheckChange}
              name='has_parking'
            />
          }
          label='Parking'
        />
      </FormGroup>
      <Button
        variant='contained'
        color='primary'
        type='submit'
        style={styles.applyButton}
      >
        Rechercher
      </Button>
    </form>
  );
};

export default Filter;
