import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToHashElement = () => {
  let { hash } = useLocation();
  const removeHashCharacter = (str) => {
    const result = str.slice(1);
    return result;
  };

  useEffect(() => {
    if (hash) {
      document.getElementById(removeHashCharacter(hash)).scrollIntoView({
        behavior: 'smooth',
        // block: "end",
        inline: 'nearest',
      });
    }
  }, [hash]);

  return null;
};

export default ScrollToHashElement;
