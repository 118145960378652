import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Routes, Route } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';

import Navbar from './components/NavBar';

import Footer from './components/Footer';
import Home from './pages/Home';
import Rental from './pages/Rental';
import RentalList from './components/RentalList/RentalList';
import ScrollToHashElement from './components/ScrollToHashElement';
import ScrollToTop from './components/ScrollToTop';
import './App.css';

// import { Description } from '@mui/icons-material';

const theme = createTheme({
  // your theme settings
});

function App() {
  return (
    <HttpsRedirect>
      <ThemeProvider theme={theme}>
        <ScrollToTop />
        <ScrollToHashElement />
        <Navbar />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/rentals' element={<RentalList />} />
          <Route path='/rentals/:search' element={<RentalList />} />
          <Route path='/rental/:id' element={<Rental />} />
        </Routes>

        <Footer />
      </ThemeProvider>
    </HttpsRedirect>
  );
}

export default App;
