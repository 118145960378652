import React from 'react';
import { Container, Box, Typography, Avatar, IconButton } from '@mui/material';
import { Email, WhatsApp } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

const Persons = ({ owner }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const styles = {
    title: {
      fontWeight: 'bold',
      color: '#384D66',
      marginTop: '30px',
      marginBottom: '10px',
      textAlign: 'center',
    },
    color: {
      color: '#384D66',
    },
    centerText: {
      textAlign: 'center',
    },
  };

  return (
    <Container maxWidth='md'>
      <Typography
        style={styles.title}
        sx={{ marginTop: '1rem' }}
        variant='h4'
        gutterBottom
      >
        Responsable
      </Typography>
      {/* Photo Section 
        <Avatar
          alt='Profile Photo'
          src={owner.photo} // Replace with your photo URL
          sx={{ width: 150, height: 150, mx: 'auto', my: 2 }}
        />
      */}
      {/* Presentation Text Section */}
      <Box style={isSmallScreen ? styles.centerText : {}}>
        <Typography variant='h6' gutterBottom>
          {owner.name}
        </Typography>
        <Typography variant='body1'>{owner.description}</Typography>
      </Box>

      {/* Social Media Links Section */}

      <Box style={isSmallScreen ? styles.centerText : {}}>
        <Box>
          <IconButton href={`mailto:${owner.email}`}>
            <Email style={styles.color} />
          </IconButton>
          {owner.email}
        </Box>
        <Box>
          <IconButton href={`tel:${owner.phoneNumber}`}>
            <WhatsApp style={styles.color} />
          </IconButton>
          {owner.phoneNumber}
        </Box>
      </Box>
    </Container>
  );
};

export default Persons;
