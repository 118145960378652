import * as React from 'react';
import { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import Logo from './Logo';

const pages = [
  {
    title: 'Accueil',
    href: '#home',
  },
  {
    title: 'Logements',
    href: 'rentals',
  },
  {
    title: 'Contact',
    href: '#contact',
  },
];

function ResponsiveAppBar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prevState) => !prevState);
  }, []);

  const styles = {
    menuIcon: {
      mr: 2,
      display: { md: 'none' },
      ml: 2,
      backgroundColor: 'black',
      '&:hover': {
        backgroundColor: 'black',
        opacity: 0.8,
      },
    },
    navButtons: {
      flexGrow: 1,
      display: { xs: 'none', md: 'flex' },
      justifyContent: 'flex-start',
    },
    drawer: {
      display: { xs: 'block', md: 'none' },
      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: 240,
      },
    },
    button: {
      my: 2,
      color: 'black',
      display: 'block',
      '&:active': {
        color: 'white',
      },
    },
    toolbar: {
      backgroundColor: 'white',
    },
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        {pages.map((item) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton
              sx={{ textAlign: 'center' }}
              onClick={(e) => handleClick(item.href)}
            >
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const navigate = useNavigate();
  function handleClick(href) {
    navigate('/' + href);
  }

  return (
    <>
      <AppBar position='static'>
        <Toolbar disableGutters sx={styles.toolbar}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={styles.menuIcon}
          >
            <MenuIcon />
          </IconButton>
          <Link to='/'>
            <Logo />
          </Link>
          <Box sx={styles.navButtons}>
            {pages.map((page) => (
              <Button
                key={page.title}
                onClick={(e) => handleClick(page.href)}
                sx={styles.button}
              >
                {page.title}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>

      <Box component='nav'>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={styles.drawer}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}
export default ResponsiveAppBar;
