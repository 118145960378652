const awsRentalsURL = 'https://probnb-public.s3.eu-west-3.amazonaws.com/owners-rentals';

let tmpData = [
  {
    owner: {
      id: 'YvanneMorelli',
      name: 'Yvanne Morelli',
      description: '',
      email: 'yvanne.cbe@gmail.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'YvanneMorelli1',
          name: 'Charmant T2, en centre ville, au coeur du Perche',
          description: "Profitez d'un logement chaleureux et calme en plein centre ville de la capitale du Perche, à deux pas de toutes commodités. L’appartement se situe dans la rue principale, à 50m de la mairie et de la place principale (Place St Pol), avec cafés, restaurants, supérette, boutiques. Un beau marché de producteurs locaux a lieu tous les samedis matins sur cette grande place. Gare, cinéma, piscine, bowling, château, office de tourisme accessibles en quelques minutes à pied. Le logement est situé au 1er étage sans ascenseur, permet une arrivée autonome ou un accueil physique par la propriétaire, et peut accueillir jusqu’à 4 personnes. Équipé de draps, serviettes, sèche-cheveux, fer à repasser, lave-linge, et une cuisine équipée. TV et Wifi fibre inclus.",
          ownerId: 'YvanneMorelli',
          area: 'Centre ville de la capitale du Perche',
          transports: "Plein centre, toutes les commodités peuvent se faire à pied. Une ligne de bus gratuite traverse la ville, l'arrêt est à 30 m du logement.",
          rooms: 2,
          beds: 1,
          capacity: 4,
          wifi: true,
          parking: false, // Supposition, à ajuster si incorrect
          coffee: true,
          type: 'Appartement',
          city: 'Nogent-Le-Rotrou (28)',
          price: '80€/nuit',
          mainFilename: 'IMG_3977.PNG',
          otherFilenames: ['IMG_4242.JPG', 'IMG_4289.JPG', 'IMG_4290.JPG', 'IMG_4291.JPG', 'IMG_4294.JPG', 'IMG_4307.JPG'],
          interest: "Forêts, circuits pédestres et VTT balisés aux alentours de Nogent, voie verte piétonne et cyclable, base de loisirs, étang et jeux pour les enfants, descente de l'Huisne en canoë, centre aquatique, bowling et cinéma en ville, château des comtes du Perche, marché de producteurs locaux les samedis matins, situé entre Chartres et Le Mans, à 1h30 des portes de Paris"
        },
      ],
    },
  },
  {
    owner: {
      id: 'RoselineDantes',
      name: 'Roseline Dantes',
      description: '',
      email: 'roselinedantes@yahoo.fr',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'RoselineDantes1',
          name: 'Studio spacieux tout équipé arrivée autonome',
          description: 'Grand studio tout équipé, arrivée autonome, 3 personnes. Situé à 6 minutes du RER A / Ligne J et L (Conflans fin d\'Oise), 30 Minutes en transport de la Défense et de la gare Saint Lazare. A 10 minutes à pied du cinéma du Pathé et le centre commercial à 5 minutes en voiture. Wi-fi fibre, Netflix, YouTube, Amazon Prime inclus. Appartement non-fumeur, possibilité de fumer à l\'extérieur sur la terrasse. Pas de fête.',
          ownerId: 'RoselineDantes',
          area: 'Rez-de-chaussée dans une rue très calme à Conflans-Sainte-Honorine',
          transports: 'Situé à 6 minutes du RER A / Ligne J et L (Conflans fin d\'Oise), 30 Minutes en transport de la Défense et de la gare Saint Lazare.',
          rooms: 'Grand Studio spacieux',
          beds: '1 lit double et 1 canapé convertible',
          capacity: 3,
          wifi: true,
          parking: false, // Assuming, adjust if incorrect
          coffee: true,
          type: 'Studio',
          city: 'Conflans-Sainte-Honorine',
          price: '75€/nuit',
          mainFilename: 'IMG_2094.jpg',
          otherFilenames: ['IMG_2105 - Copie.jpg', 'IMG_2113.jpg', 'IMG_2117.jpg', 'IMG_2127.jpg', 'IMG_2153.jpg', 'IMG_2157.jpg', 'IMG_2161.jpg', 'IMG_2216.jpg', 'IMG_2259.jpg'],
          interest: 'Le studio se trouve au Rez-de-chaussée dans une rue très calme, avec un bar / tabac, des restaurants, Mcdo, une épicerie devant la gare. Le Restaurant Little Tokyo est à 5 minutes en voiture, ouvert du Mardi au Dimanche.'
        }
      ]
    }
  },
  {
    owner: {
      id: 'Aissata',
      name: 'Aissata',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'Aissata1',
          name: 'Le Sweet Nook - métro mairie de Montreuil',
          description: 'Bienvenue au Sweet Nook! À quelques minutes à pied du métro "Mairie de Montreuil", ce logement offre un accès facile aux transports en commun, commerces, restaurants, et cafés. Idéal pour des séjours professionnels, romantiques ou familiaux, il propose un environnement doux et chaleureux. Le studio est optimisé pour le confort avec un coin nuit, salon TV (Netflix, Amazon Prime Vidéo), Wifi (fibre optique), espace bureau/dinatoire, cuisine équipée, et un coin dressing. Profitez également de la décoration lumineuse pour des soirées cinéma ou du travail en toute sérénité.',
          ownerId: 'Aissata',
          area: '3ème et dernier étage sans ascenseur à Montreuil, France, près du parc Beaumont, cinéma les Méliès, et la rue piétonne Dreyfus.',
          transports: 'À quelques minutes à pied du métro "Mairie de Montreuil", le bus 129 à 300m, avec de nombreux commerces, restaurants et cafés à proximité.',
          rooms: 1,
          beds: 1,
          capacity: 2,
          wifi: true,
          coffee: true,
          type: 'Studio',
          city: 'Montreuil, France',
          price: 'Pas mentionné',
          mainFilename: '2102809d-7941-4498-a31a-80b5ff7ef651.webp',
          otherFilenames: [
            '0fdf5a73-3d53-4b2d-bac9-0afedb45e259.webp',
            '2e92964a-6ff9-4373-b98c-fae88c33f3b0.webp',
            '3d5db0da-190a-4bed-9c82-7ec0e650e5bc.webp',
            '5e89b90e-0767-48b0-b508-12c9fcf4c58a.webp',
            '61164141-2346-4a9c-9685-7a1fb50eeb89.webp',
            '72150e50-c28b-42aa-9cdd-33aac309ccee.webp',
            '9321ce1d-feba-430f-b518-cf4948a04988.webp',
            'ce90af43-3fac-4f98-816d-becffe7fdbe6.webp',
            'd03a23cf-b2c7-46e4-a4c8-6217d17484e9.webp',
            'dabcfdf6-258d-4d7d-a4f2-01cbdd410fc2.webp',
            'db61c8eb-1122-43b0-bed1-26dc5cf5ec46.webp',
            'e7b27a19-7cbe-4e94-999d-c116d13c31e3.webp',
            'ee208634-8916-49c9-bad9-e1594f0f3ac9.webp'
          ]
        }
      ]
    }
  },
  {
    owner: {
      id: 'RuthRachel',
      name: 'Ruth Rachel',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'RuthRachel1',
          name: 'Le Warm Cocon - spacieux T3 proche Paris',
          description: 'Bienvenue au Warm Cocon! Que vous soyez en déplacement professionnel, escapade romantique, entre amis ou en famille, le Warm Cocon vous plongera dans une ambiance cocooning et chaleureuse. Situé au RDC, ce spacieux logement de 68 m2 avec deux chambres bénéficie d\'un parking privatif gratuit. Profitez d\'un cadre paisible aux portes de Paris et du centre commercial Westfield à 10 min en voiture. Le logement est parfaitement aménagé avec un espace salon TV, Wifi (fibre optique), bureau/dinatoire, cuisine équipée, et placards de rangement.',
          ownerId: 'RuthRachel',
          area: 'RDC à Rosny-sous-Bois, France, près des commodités et des transports en commun pour un accès facile au centre de Paris.',
          transports: 'Transports en commun à proximité permettant de rejoindre facilement le centre de Paris et ses attractions emblématiques.',
          rooms: 3,
          beds: 3,
          capacity: 5,
          wifi: true,
          coffee: true,
          type: 'Appartement',
          city: 'Rosny-sous-Bois, France',
          price: 'Pas mentionné',
          mainFilename: '75d33bd4-7cbb-4021-a5a9-8ce17b108b52.webp',
          otherFilenames: [
            '0467a0c7-bd0a-4c79-99fa-bcb230aa5161.webp',
            '0d5f7a00-52ce-4725-9203-26f21a373b72.webp',
            '1f57f2e1-192b-4c42-98e4-1addf0d02ac0.jpeg',
            '2b1f1c6c-b35a-4cf8-9bcf-c7b5f1c1fbaf.webp',
            '40bcf664-0b6a-4c42-972f-50e524ebf8e4.webp',
            '5dcea892-d878-4770-8b3c-ec6107191586.webp',
            '66d415f1-51d3-4891-9baa-790c0e256847.webp',
            '788735d9-743a-4c26-be6e-96c64acb7c24.webp',
            '7a8d1eaf-435b-472f-8626-40c29f0cc9c4.webp',
            '7af0d92a-c7e4-4718-bd8f-5e6a6faabe1a.webp',
            '86aa4a66-cac1-4cc3-bddd-c5efc4e56e68.webp',
            '9511756f-9251-4dc6-86ea-bb5e11cf975a.webp',
            'a463dccf-fb71-40ce-a297-5819c81ff780.webp',
            'b79a0f44-5137-42a1-88e3-bc4b4aba7f8d.webp',
            'c2409633-2518-4a66-aef8-f201b05e39a7.webp',
            'e0890bfe-5077-4898-90b6-fd2ed558d184.webp'
          ]
        },
        {
          id: 'RuthRachel2',
          name: 'Studio avec parking* 10 min de la Défense-U Arena',
          description: 'Besoin de vous sentir comme enveloppé dans une bulle, vous êtes au bon endroit ! Profitez de votre séjour dans ce studio ultra lumineux, fonctionnel et charmant. Situé au 1er étage d’un immeuble bien entretenu, sous vidéo surveillance avec une place de parking, il peut accueillir jusqu’à trois personnes. La gare de Houilles Carrières-Sur-Seine est à seulement 3 minutes à pieds pour rejoindre la Défense, La gare Saint-Lazare, Disneyland-Paris, les Champs-Elysées, etc. Fêtes interdites, immeuble sous vidéo surveillance. Animaux non acceptés.',
          ownerId: 'RuthRachel',
          area: '1er étage, immeuble sous vidéo surveillance à Houilles, France.',
          transports: 'La gare de Houilles Carrières-Sur-Seine est à seulement 3 minutes à pieds pour rejoindre la Défense, La gare Saint-Lazare, Disneyland-Paris, les Champs-Elysées, etc.',
          rooms: 1,
          beds: 1,
          capacity: 3,
          wifi: true,
          coffee: true,
          type: 'Appartement',
          city: 'Houilles, France',
          price: 'Pas mentionné',
          mainFilename: '5e391ef6-7ad7-44bf-8ae7-1019083a5b4d.webp',
          otherFilenames: [
            '22adf2a2-c4d9-4345-91e5-4785e722e0de.webp',
            '2814daf1-d8fd-4304-b984-aa934209e239.webp',
            '418521ab-2b25-4e3e-a6b6-2438a8a8256c.webp',
            '43480b7e-8afd-4e79-8e12-018e9ae341be.webp',
            '46136ab5-d2d4-4612-80b1-4119a8990fd2.webp',
            '47884266-de8d-4352-9d33-9c571b7898be.webp',
            '4d17d737-b201-4b02-98f8-7ac475a55afe.jpeg',
            '8353222a-a6f4-43e5-a818-b09425816cd8.webp',
            '8711a327-283e-47b9-9ce0-db3bf609eb48.webp',
            '8cc5dba4-5899-4fa5-85eb-8cb499b1c289.webp',
            'a6d6fa0e-45d8-4f48-9da4-d4c556572d21.webp'
          ]
        },
        {
          id: 'RuthRachel3',
          name: 'Le Modern Chic - Disneyland/Val d\'Europe à 10 min',
          description: 'Bienvenue au Modern Chic! Découvrez cet élégant studio de 27 m2 situé à Montévrain, parfait pour profiter du charme de la capitale, visiter Disneyland et faire du shopping au Val d\'Europe. Situé à 45 minutes de Paris via le RER A (Montévrain Val d\'Europe), à proximité de la Salle de spectacle Le Millésime et du Parc commercial Clos du Chêne. L\'appartement, conçu pour 2 voyageurs, offre un lit double, haut débit WIFI, TV HD, une cuisine équipée, et une salle de bain avec baignoire. Draps et serviettes fournis pour un séjour agréable dans un quartier paisible et sûr.',
          ownerId: 'RuthRachel',
          area: '1er étage avec ascenseur à Montévrain, France, proche de la Salle de spectacle Le Millésime et du Parc commercial Clos du Chêne.',
          transports: '45 minutes de Paris en voiture et via le RER A (station Montévrain Val d\'Europe).',
          rooms: 1,
          beds: 1,
          capacity: 2,
          wifi: true,
          coffee: true,
          type: 'Appartement',
          city: 'Montévrain, France',
          price: 'Pas mentionné',
          mainFilename: '6bf08296-85f9-4ac4-b055-0bebae7922ba.webp',
          otherFilenames: [
            '042442be-bc1e-426c-b5b5-2c85b1d98bda.webp',
            '0a49b587-e1bb-4a48-947f-8c22a6de4dfd.webp',
            '4353f344-59a2-40cd-887e-e949a9a125cc.webp',
            '64b9c694-ecef-4168-9319-060b1f2b4ac0.webp',
            '78217271-dfd3-4e53-88a1-21fac44ace1f.webp',
            '87878324-af16-413c-ab17-7e00825292fd.webp',
            '9e81ffdb-b653-4bcc-ab4a-53e505154429.webp',
            'c6b66c96-37a7-4774-aa6f-3ac4d458baba.webp',
            'c71eea5b-978c-4a9b-9bea-ab379dff827d.webp',
            'dd1af1f7-cb06-4a11-b733-3c387937d85d.webp'
          ]
        },
        {
          id: 'RuthRachel4',
          name: 'La Parenthèse Cosy - Disneyland/Val d\'Europe',
          description: 'Bienvenue à La Cocooning Parenthèse. Découvrez ce magnifique studio de 30 m2 situé à Noisy le Grand, parfait pour étudiants, professionnels, couples, ou amis. Proche de Paris et Disneyland, ainsi que le campus universitaire de Gustave Eiffel et l\'ENFIP. Équipé d\'un lit double, WIFI, TV HD, cuisine équipée, et salle d\'eau. Draps et serviettes fournis, avec parking public disponible.',
          ownerId: 'RuthRachel',
          area: 'Noisy-le-Grand, France, proche de Paris et Disneyland. À côté du campus universitaire de Gustave Eiffel et commodités.',
          transports: 'Stratégique emplacement proche de Paris et Disneyland, accessible en 20 min en train. À proximité de supermarchés et du Centre Commercial Régional des Arcades.',
          rooms: 1,
          beds: 1,
          capacity: 2,
          wifi: true,
          coffee: true,
          type: 'Appartement',
          city: 'Noisy-le-Grand, France',
          price: 'Pas mentionné',
          mainFilename: '04bc8fbd-55d2-4124-bb5b-0da3a2e8d757.webp',
          otherFilenames: [
            '3cc53fcb-4f32-45c1-8788-62c0debd5b54.webp',
            '5186125d-7646-4f1f-84f4-426d397c56dd.webp',
            '5f715a4a-a92b-4e01-8cdc-83b63bc4f0a6.webp',
            '6c7b04eb-f31d-4fd8-b600-62dcfb7cad92.webp',
            'a975fb15-660a-409a-8cd1-03e50e8fae01.webp',
            'bfbc28b3-87d0-4e48-bef3-ac7b822a6449.webp',
            'ca1992f6-95b8-40bb-bcd0-d9379dff6c8f.webp',
            'd3c1ce78-dded-487c-ad6d-9c5d454fd600.webp',
            'e2f0bef8-1c7b-42f7-a9ae-db924f25d0f4.webp',
            'f2fc6b72-7439-4840-a761-01f69c627afa.webp',
            'f5ea701f-f03f-4b76-85cb-3b16204f6afc.webp'
          ]
        },
      ]
    }
  },
  {
    owner: {
      id: 'Gerty',
      name: 'Gerty',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'Gerty1',
          name: 'Appartement zen',
          description: 'Bel appartement T4 de 67 m2 refait à neuf situé au 4ème sans ascenseur dans la commune de Champigny sur Marne, dans une résidence calme et sécurisée. À 10 min en bus du centre-ville et de la gare, avec un arrêt de bus au pied de l\'immeuble. À la jonction de Paris et Disneyland Paris, cet appartement meublé offre tout le confort nécessaire. À proximité: un bar tabac et une épicerie de proximité, ouverts 6 jours sur 7.',
          ownerId: 'Gerty',
          area: '4ème étage sans ascenseur, Champigny-sur-Marne, France, dans une résidence calme et sécurisée.',
          transports: '10 min en bus du centre-ville et de la gare. Un arrêt de bus au pied de l\'immeuble.',
          rooms: 4,
          beds: 3,
          capacity: 6,
          wifi: true,
          coffee: true,
          type: 'Appartement',
          city: 'Champigny-sur-Marne, France',
          price: 'Pas mentionné',
          mainFilename: 'e0de9aa2-68f0-4690-8a17-e229605ad537.webp',
          otherFilenames: [
            '4b8de74f-ad8b-4a78-8956-cef10665a686.webp',
            '543a4390-0bdb-441a-962f-da220bf5bfe8.webp',
            '8c8099ea-8471-41e2-8698-df85d843bd3f.webp',
            '9244339e-2d73-4100-a2e7-c519dd54645b.webp',
            'a284236b-41e6-4d30-866d-0badff62a3a5.webp',
            'bf33fb63-2660-4e27-9fcc-43ca5ceb3413.webp',
            'cc9dda64-1fe6-471b-b4cc-a40d7ad1134d.webp',
            'd6063145-9382-459d-84fd-a44278f8c900.webp'
          ]
        }
      ]
    }
  },
  {
    owner: {
      id: 'Junior',
      name: 'Junior',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'Junior1',
          name: 'Cosy Appartement proche de Paris',
          description: 'Bienvenue dans notre charmant appartement à Noisy-le-Sec! Un véritable havre de paix alliant confort moderne et atmosphère chaleureuse. Situé idéalement à 15 min en RER E de Paris et quelques minutes à pied des gares RER E Rosny-Bois-Perrier et Rosny-sous-Bois. Proche de Bus (105, 143, 545) et des centres commerciaux Westfield Rosny 2 et DOMUS. L\'appartement, refait à neuf, offre une première chambre avec lit double, une deuxième chambre avec lit simple, une cuisine équipée, et une salle de bain avec baignoire. Linge de lit, de toilette, gel douche, et café fournis.',
          ownerId: 'Junior',
          area: 'Noisy-le-Sec, France, à proximité immédiate des transports et commerces.',
          transports: '15 min en RER E de Paris, arrêts de Bus à 2min, proche des gares RER E Rosny-Bois-Perrier et Rosny-sous-Bois.',
          rooms: 3,
          beds: 2,
          capacity: 3,
          wifi: true,
          coffee: true,
          type: 'Appartement',
          city: 'Noisy-le-Sec, France',
          price: 'Pas mentionné',
          mainFilename: 'c4cd5c39-7786-4efb-bc2b-e2db710590ab.webp',
          otherFilenames: [
            '58287c5d-2a13-49a6-b560-be5fcd2a0fdd.webp',
            '6478a129-44ad-4a0f-9f9b-afc8ef6f97cc.webp',
            '74af5ded-7b85-4e15-9f9a-ead052934063.webp',
            '74d60fb0-9e0b-410e-8467-d520435f7c28.webp',
            '7c594a43-80a8-4bda-a409-98e046e14d1e.webp',
            '90f2b074-1cb9-45ea-a7d5-e941173d6ab0.webp',
            'bea5fbbc-73af-4988-8cff-ee296daf25fb.webp',
            'f55045b7-4728-4b01-8373-b2385428fe06.webp',
            'fb4cf532-5536-4e8c-82e8-f5d6cec177c2.webp',
            'ffedd89f-ba4c-485d-804e-1885281527d3.webp'
          ]
        }
      ]
    }
  },
  {
    owner: {
      id: 'Eden',
      name: 'Eden',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'Eden1',
          name: 'Eden\'Home "Emeraude" votre Nid douillet',
          description: 'Détendez-vous dans ce logement calme, élégant, et fonctionnel, pensé pour votre bien-être. Situé dans un quartier pavillonnaire de Bobigny, proche des commodités et à 10-15 min de marche du centre-ville et métro 5. L\'appartement au 2e étage peut accueillir jusqu\'à 4 personnes, offrant un lit double et un canapé-lit. Équipé de tout le nécessaire: cuisine équipée, Nespresso, douche équipée, WIFI. Des réductions sont disponibles pour les longs séjours.',
          ownerId: 'Eden',
          area: '2e étage d\'un immeuble de 3 niveaux, quartier pavillonnaire de Bobigny, à proximité d\'une épicerie, station service, et à 10-15 min de marche du centre-ville et métro 5.',
          transports: 'Arrêts de bus à 3 min à pied. Proche du centre-ville de Bobigny et métro 5 permettant un accès facile à la Gare du Nord, la Gare de l\'Est et Paris Austerlitz.',
          rooms: 1,
          beds: 2,
          capacity: 4,
          wifi: true,
          coffee: true,
          type: 'Appartement',
          city: 'Bobigny, France',
          price: 'Pas mentionné',
          mainFilename: '435035b5-d1a6-4f85-9522-c1feffb6c3a3.webp',
          otherFilenames: [
            '00f8638f-e111-4452-9ccf-1c4c6caf2a5f.webp',
            '0fcfc5b0-f171-4da2-bf65-cf789fd66dcf.webp',
            '11b4acd8-b71a-4d14-a4f2-c761451e2fe4.jpg',
            '3ce1e27c-e758-4650-b437-75ea75429699.webp',
            '5712146b-9148-4f4b-b4b5-3463cb5c46d8.jpg',
            '75f95621-3c2e-4dca-ad40-8c96a80282b3.webp',
            '8335f1b1-f101-4f17-8e32-be2e72090e6f.webp',
            '857c7323-e00f-4298-8f68-48201c67ec0f.webp'
          ]
        }
      ]
    }
  },
  {
    owner: {
      id: 'Isabelle',
      name: 'Isabelle',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'Isabelle1',
          name: 'Le Paradis de Lille au pied du Métro',
          description: 'Le petit Paradis de Lille offre un espace de vie chaleureux avec une décoration moderne. Il comprend un salon avec canapé-lit double, un espace bureau, une table à manger, une TV avec Netflix, une chambre avec lit double et canapé-lit, une cuisine équipée, et une salle de bain. Possibilité de 5 couchages et un espace jeux pour enfants. Machine à café Tassimo et produits d\'accueil disponibles. Situé à Mons en Baroeul, avec le métro au pied de l\'immeuble, il est proche de centres commerciaux, restaurants, et espaces verts.',
          ownerId: 'Isabelle',
          area: 'Mons-en-Barœul, France, avec le métro Mons en Baroeul au pied de l\'immeuble, proche de centres commerciaux Euralille et Villeneuve d\'Adcq, et à quelques minutes du centre de Lille.',
          transports: 'Métro Mons en Baroeul au pied de l\'immeuble, accès facile à la gare Lille Flandre et Lille Europe, ainsi qu\'au centre-ville de Lille et attractions à proximité.',
          rooms: 2,
          beds: 3,
          capacity: 4,
          wifi: true,
          coffee: true,
          type: 'Appartement',
          city: 'Mons-en-Barœul, France',
          price: 'Pas mentionné',
          mainFilename: 'a00002e6-06a4-41e7-a212-97686e0cca0a.webp',
          otherFilenames: [
            '00abb10f-4648-442c-bfa1-c2db07d21e20.webp',
            '10fac91a-e557-42ca-b503-65e40b9cc6ac.webp',
            '2a51d1b9-6e4f-44ea-ab5f-8fa23880065b.webp',
            '357afcd0-900f-439b-b379-ff0fa5850468.webp',
            '45428ee9-39dc-4edc-a963-386daae5cab3.webp',
            '4d22c6f0-f062-4ce4-a517-1e09c46bdc55.webp',
            '63a173ac-745d-4d75-81be-6ee01a1f2358.webp',
            '75446efc-4ddc-4195-862d-8afad3133f28.webp',
            '7e5e4970-9596-423f-b951-4d96047b29f2.webp',
            '880113e5-947b-47a3-ad6d-fd9ffc9876ba.webp',
            '8d3c3c08-257d-4d7c-b9b8-7165454a35ca.webp',
            'b00cace8-5277-4f2b-b1b9-ce5bb723747a.webp',
            'c907afe0-77fd-4154-8610-24faab50f1fd.webp',
            'cceea1aa-be10-4111-a0bc-4595468df34b.webp',
            'e2be0253-700a-469b-ac43-2e122061bd3a.webp',
            'e5d64586-cc70-4516-87c2-80cfe6588b33.webp'
          ]
        },
        {
          id: 'Isabelle2',
          name: 'Le petit parisien - Gare Epinay Villetaneuse',
          description: 'Découvrez un bout de Paris avec cet appartement offrant une vue sur la Tour Eiffel et La Défense. Situé dans un quartier bourgeois et calme de Montmagny, proche de supermarchés et transports en commun. L\'appartement est doté d’une décoration épurée et moderne, idéal pour des séjours de travail ou des week-ends romantiques. Il dispose d\'un balcon avec vue panoramique, 4 couchages, une cuisine équipée, et accès à Netflix et boissons chaudes à volonté. Sécurité renforcée dans les parties communes et extérieurs.',
          ownerId: 'Isabelle',
          area: 'Quartier bourgeois et calme de Montmagny, vue sur la Tour Eiffel et La Défense, proche de supermarchés et restaurants, avec accès facile à la gare d’Epinay Villetaneuse et à 2 arrêts de la Gare du Nord.',
          transports: 'Bus à 1 arrêt de la gare d’Epinay Villetaneuse; à 2 arrêts de la Gare du Nord via la gare de Saint-Denis.',
          rooms: 1,
          beds: 2,
          capacity: 4,
          wifi: true,
          coffee: true,
          type: 'Appartement',
          city: 'Montmagny, France',
          price: 'Pas mentionné',
          mainFilename: 'e0790be3-96c9-452d-b4c4-d14830a54f53.webp',
          otherFilenames: [
            '011667cb-aa9b-4e86-bcf0-ea18477e75a7.webp',
            '18bb9102-089c-4f0a-86e4-3b82fc198c62.webp',
            '3ba84a52-9d4d-4aff-8cd9-c1736b7419e0.webp',
            '3cbf147b-a3b7-4d5e-9bb7-fcfe550764be.webp',
            '63b8024c-b26b-43cf-b8ec-2ea5fc8b5b1e.webp',
            '841b18f1-6bdf-43fb-ac7f-25fad47ad1fe.webp',
            'de75cf6b-b74c-41c0-9d99-52fb98e90085.webp'
          ]
        },
        {
          id: 'Isabelle3',
          name: 'La Pause Parisienne',
          description: 'La Pause Parisienne est un appartement cosy situé dans un immeuble calme, loin du bruit parisien. Avec une décoration épurée et élégante, il est parfait pour un séjour reposant. Fonctionnel et bien équipé avec TV, wifi, et Netflix. Un havre de paix vous attend!',
          ownerId: 'Isabelle',
          area: 'Dans un petit immeuble calme à Saint-Denis, à l’abri du bruit parisien, offrant un séjour agréable et reposant.',
          transports: 'Pas de point de transport énoncé spécifiquement.',
          rooms: 1,
          beds: 1,
          capacity: 2,
          wifi: true,
          coffee: false,
          type: 'Appartement',
          city: 'Saint-Denis, France',
          price: 'Pas mentionné',
          mainFilename: '1a0d0386-6d6f-4163-9455-77b80d4e3196.webp',
          otherFilenames: [
            '1c6b36c6-2801-451f-a2fe-6c991c97e761.webp',
            '6c20673f-0cf5-4519-aa73-fc3baac2a017.webp',
            '8ce6a404-82d5-452e-b85b-cddd1aa3784d.webp',
            '9f552e30-30ec-4d83-b34f-99a2033c129c.webp',
            'c6fe3c80-b385-4727-bf0c-a26067b4411e.webp'
          ]
        },
        {
          id: 'Isabelle4',
          name: 'Saint-Brice de Paris',
          description: 'Pas de description.',
          ownerId: 'Isabelle',
          area: 'Saint-Brice-sous-Forêt, France, sans points d\'intérêt ou de transport énoncés spécifiquement.',
          transports: 'Pas de point de transport énoncé.',
          rooms: 1,
          beds: 1,
          capacity: 2,
          wifi: false,
          coffee: false,
          type: 'Appartement',
          city: 'Saint-Brice-sous-Forêt, France',
          price: 'Pas mentionné',
          mainFilename: 'd3fdc3e3-67e5-4dcd-834d-e30d9bc3c473.webp',
          otherFilenames: [
            '35e6e410-eb1d-40d0-8b6c-31c734f8e181.webp',
            '3a15c6b1-1e9e-491f-8cf4-583ea9504d66.webp',
            '4d8034d0-a694-4cd6-99ba-a1ef9a401b12.webp',
            '54bee0e4-4b8b-4afc-8abc-d118de16a4d9.webp',
            '5b39e914-423f-4a5e-bdea-dced9d441eaf.webp',
            '82629887-d9d3-48cf-a60c-16dde96b1d70.webp',
            '975cf9a0-e820-4a90-a230-765bea9b5d06.webp',
            'c9e33def-20dc-49ff-9da6-61bb66b4c536.webp'
          ]
        }
      ]
    }
  },
  {
    owner: {
      id: 'Audrey',
      name: 'Audrey',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'Audrey1',
          name: 'Studio Carquefou-Parking inclus',
          description: 'Élégant studio à Carquefou, rue de l’hôtellerie, sur une avenue calme proche des autoroutes. Idéal pour 2 voyageurs, avec wifi haut débit, parfait pour télétravail. Cuisine équipée d’un frigo, micro-ondes, plaque de cuisson, bouilloire, machine à café (capsules et thé fournis), ustensiles. Parking disponible dans la résidence. Situé près de Nantes, avec un accès facile aux autoroutes, tram, et bus. Fêtes et soirées interdites, non-fumeur.',
          ownerId: 'Audrey',
          area: 'Carquefou, rue de l’hôtellerie, sur une avenue calme proche des autoroutes. Près de Nantes, accès facile aux autoroutes, tram, bus.',
          transports: 'Près de Nantes, accès facile aux autoroutes, tram, bus.',
          rooms: 1,
          beds: 1,
          capacity: 2,
          wifi: true,
          coffee: true,
          type: 'Appartement',
          city: 'Carquefou, France',
          price: 'Pas mentionné',
          mainFilename: '1a1aabe3-cfe2-4ce4-8ce7-c60ff6bd3d6b.webp',
          otherFilenames: [
            '002207b6-8970-46c0-94c9-99ceb45a61f5.webp',
            '1d80dc8f-1db6-46a4-ba3c-c2868e685913.webp',
            '2e5a7206-9285-4e2f-b56f-61f938a2f26a.webp',
            '3402b6a1-1b09-4e19-b853-cd91591cee1d.jpeg',
            '5f715a4a-a92b-4e01-8cdc-83b63bc4f0a6.webp',
            'b18aed31-3dd7-4645-b88e-c0cbadddfa97.webp',
            'c8b71b0c-4d68-4052-80c1-26a58525ffdc.webp'
          ]
        }
      ]
    }
  },
  {
    owner: {
      id: 'Nadia',
      name: 'Nadia',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'Nadia1',
          name: 'Appartement meublé Cocody Angré',
          description: 'Appartement spacieux et lumineux avec vue dégagée, idéalement situé entre le nouveau CHU d\'Angré et le château d\'eau. Commerces aux alentours, un carrefour Market à 7 min en voiture. Parking (interne et externe) gratuit. Equipé d\'un grand salon/salle à manger, cuisine entièrement équipée, canapé angle 7 places, TV connectée 55 pouces, Wifi, canal+, chambre avec lit double 180/200 cm, 2 placards dressing. Draps et serviettes fournis. Au 4e étage avec ascenseur, accueille 2 personnes, 2 balcons. Cuisine équipée, salon avec TV connectée, Wifi. Nécessaire pour le jour d\'arrivée fourni.',
          ownerId: 'Nadia',
          area: 'Situé entre le nouveau CHU d\'Angré et le château d\'eau à Abidjan, Côte d’Ivoire. Proximité avec épiceries, pressing, et le célèbre marché de Cocovico.',
          transports: 'Idéalement situé avec accès facile aux commerces, centre-ville, restaurants, cinéma, et plus.',
          rooms: 2,
          beds: 1,
          capacity: 2,
          wifi: true,
          coffee: true,
          type: 'Appartement',
          city: 'Abidjan, Côte d’Ivoire',
          price: 'Pas mentionné',
          mainFilename: 'ac1e69bd-2d93-4662-9cfd-f85551530bd1.webp',
          otherFilenames: [
            '010ae1ac-c75b-4031-a189-c5b8fce8ccfe.webp',
            '0db7d589-9fbd-4629-850d-7ceb5c811f54.webp',
            '5411a37f-7e4f-42c9-834b-dc027919b5e4.webp',
            '7a9be001-51cf-4678-8e5c-384742399d82.webp',
            '81420aad-35bd-415c-af32-69dee9530778.webp',
            '8bbf1525-168e-4e69-bfa2-3e3150cd7070.webp',
            '90e20522-1628-40d0-8e79-a613c2c3f141.webp',
            'ad002939-a361-4cf1-87c2-60826408b808.webp',
            'ea696529-3127-40ba-aea4-a7ed4d6ebfbe.webp'
          ]
        }
      ]
    }
  },
  {
    owner: {
      id: 'Joelle',
      name: 'Joelle',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'Joelle1',
          name: 'Le Comfy spot - Porte de Paris',
          description: 'Bienvenue au Comfy Spot! Un studio moderne et confortable, idéalement situé à quelques pas du métro Aubervilliers-Pantin quatre chemins (ligne 7). Que vous soyez ici pour un voyage touristique, un déplacement professionnel, notre logement bien équipé, a été soigneusement aménagé pour vous offrir un séjour agréable. Le métro est accessible à 3 min à pied. Un parfait pied à terre pour visiter Paris et profiter des JO de Paris 2024. Il n\'attend plus que vous!',
          ownerId: 'Joelle',
          area: 'A quelques pas du métro Aubervilliers-Pantin quatre chemins (ligne 7), idéal pour visiter Paris et les JO de Paris 2024.',
          transports: 'Le métro est accessible à 3 min à pied, permettant un accès facile au centre de Paris et à ses attractions emblématiques comme les Champs Elysées et la tour Eiffel.',
          rooms: 1,
          beds: 1,
          capacity: 2,
          wifi: true,
          coffee: true,
          type: 'Appartement',
          city: 'Aubervilliers, France',
          price: 'Pas mentionné',
          mainFilename: '563e2c23-b8f5-4088-97c6-ea2285e03108.webp',
          otherFilenames: [
            '1e9dd23f-05d7-4190-bd06-04713356e214.webp',
            '485d2c63-bbae-4af4-b217-350e03c9a7f0.webp',
            '86c3cce6-59f6-40f4-9a16-e46150e3e27c.jpeg',
            '9bbbb31d-6725-4d42-ba7f-37f6a9b48951.webp',
            'a9db99e5-3113-42d9-9142-37b6ca52a7be.webp',
            'aa35545f-983c-445d-8de1-5321e692e073.jpg',
            'f7e1fba7-5d73-492d-aa13-fc0f381f1dda.webp'
          ]
        },
        {
          id: 'Joelle2',
          name: 'Le Stylish Spot - Montreuil',
          description: 'Bienvenue au Stylish Spot ! Un studio cosy et tout confort, situé à Montreuil une ville dynamique proche de Paris. Venez poser vos valises et profitez d\'un séjour paisible.',
          ownerId: 'Joelle',
          area: 'Montreuil, France',
          transports: 'Le logement est situé à 15 min en bus du métro Croix de Chavaux (métro 9) ou de la gare Val de Fontenay (RER A/E)',
          rooms: 1,
          beds: 1,
          capacity: 2,
          wifi: true,
          parking: false,
          coffee: true,
          type: 'Appartement',
          city: 'Montreuil, France',
          price: 'Pas mentionné',
          mainFilename: '39d319b2-8461-4711-be4e-80eb24ce4f69.webp',
          otherFilenames: [
            '2be92387-b4d7-41b9-b9e6-10962ba6a525.webp',
            '4793aed5-b775-4300-8b00-fa42f3f5ca84.webp',
            '74ee98ca-8e67-43df-8943-3789ec31cbcf.webp',
            '7afed75a-8a29-47fb-8bba-3997ed10c697 (1).jpeg',
            '85c7e524-50cd-44a1-8057-c428bca928f4.webp',
            '8b7a85ac-9f33-4835-9c0c-b6a7118d8ac9.webp',
            'bdfaa878-40ab-43c6-81a8-637ac26954ec.webp',
            'c1498169-1e2c-4270-8192-9c49bc5e464e.webp',
            'f02db086-a327-4e5a-8539-1430abd7a545.webp'
          ],
          interest: 'Situé à proximité de St Remy et accès facile aux Alpilles directement depuis la maison.'
        },
      ]
    }
  },
  {
    owner: {
      id: 'Tania',
      name: 'Tania',
      description: '',
      email: 'houefa.immo@gmail.com',
      phoneNumber: '+33662043583',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'Tania1',
          name: 'Doux nid près du cœur de Paris',
          description: 'Bienvenue chez vous! Découvrez votre coin de paradis dans un immeuble sécurisé avec camera de surveillance. Paris n\'est qu\'à quelques instants. Studio spacieux de 35m², flambant neuf, avec levés de soleil inédits, pièce lumineuse sans vis à vis, à 8min des RER A et E (Val de fontenay) et bus (118, 301, 122). Trajets directs sur le RER A vers Châtelet Les Halles, Disneyland, La Défense, et Galeries Lafayette. Profitez du lit double, canapé-lit, Smart TV avec Netflix, Disney, Amazon Prime Video, Fibre optique, parking gratuit. Cuisine entièrement équipée, coin café parfait pour démarrer votre journée. Salle de bain avec bain, savon, gel douche, sèche-cheveux.',
          ownerId: 'Tania',
          area: 'Proche de Paris, dans un immeuble sécurisé avec camera de surveillance. A 8min des RER A et E (Val de fontenay) et bus. Centre commercial « Val De Fontenay » à 5 min.',
          transports: 'A 8min des RER A et E (Val de fontenay) et bus (118, 301, 122). Trajets directs sur le RER A vers Châtelet Les Halles, Disneyland, La Défense, et Galeries Lafayette.',
          rooms: 1,
          beds: 2,
          capacity: 4,
          wifi: true,
          coffee: false,
          type: 'Appartement',
          city: 'Fontenay-sous-Bois, France',
          price: 'Pas mentionné',
          mainFilename: 'f8fa1ec4-9482-4815-b80d-a607061cfc67.webp',
          otherFilenames: [
            '22bcbe89-e439-44f6-b669-9e03dcea9da4.webp',
            '342f50d8-8c9b-481d-83f1-81d02dda19ce.webp',
            '66ff2a59-b262-4b78-a267-f7d5b748f099.webp',
            '721082b0-efff-4f65-af7d-e2e22421c452.webp',
            '75adf1b8-0762-4504-b3ff-66735be610f0.webp',
            '75d08da6-703d-4286-ae29-169a4a9dcb10.webp',
            'aee7d17d-b6eb-4164-bdc5-d841f6044c31.webp',
            'c5705d9e-cbaf-4d26-ae04-b1af441e71a8.webp',
            'ff67e512-2d80-4ad6-baf9-971256e35903.webp'
          ]
        },
        {
          id: 'Tania2',
          name: 'Le Bleu Soleil • à 2 min du métro • 7 min de Paris',
          description: 'Plongez-vous dans le confort de ce superbe 2-pièces de 55m2, idéalement situé à 2mn du métro et magnifiquement aménagé pour votre confort maximal. Décoré avec goût et amour, chaque coin de cet appartement vous rappelle le confort de chez vous.',
          ownerId: 'Tania',
          location: 'Maisons-Alfort, France',
          transports: 'À deux pas du métro 8, rejoignez le cœur de Paris en seulement 7 minutes.',
          interest: 'À 16 minutes à pied, le RER D vous ouvre les portes du Stade de France ou du centre de Paris en un rien de temps. Pour les mélomanes, Bercy n\'est qu\'à 7km – une courte escapade pour vos concerts à l\'Accor Arena. Hôpital Henri Mondor et faculté de Médecine à 10 minutes en métro Université Paris Est Créteil à 10mn en métro Bois de Vincennes (3.5km), Parc Floral (5mn), Hippodrome (3,8km), Château de Vincennes (4,8km)',
          rooms: 1,
          beds: 2,
          capacity: 4,
          wifi: true,
          coffeeMachine: true,
          type: 'Appartement',
          price: 'Pas mentionné',
          mainFilename: '00b33501-75e3-4c22-9ae4-dfbe43c2f6f1.webp',
          otherFilenames: [
            '03b0be85-f46d-46cc-b557-5dd80b6546c9.webp',
            '09c1ef1c-52ea-4152-b236-80a664d14404.webp',
            '3a900503-ea8f-4b50-b100-4292a6990024.webp',
            '6116612b-404a-4ac0-bfbf-cb5b661464be.webp',
            'a0f560ef-594f-4c5b-b137-ace6f5fd635e.webp',
            'a9fcfdc4-8705-48bc-93b6-a5dd38b09254.webp',
            'c6c3df46-8335-47a1-82a5-8a6cd1189f37.webp',
            'db98c83a-6609-4832-88f8-cfdc46b1b910.webp',
            'f337905a-c52d-45e0-ab85-12b9ddbcb99e.webp',
            'f50e500b-fdc4-4514-9f55-7cc5aa0a4d6b.webp',
            'fe80d68f-aab6-4dfe-8a96-2a77a337d640.webp'
          ]
        },
      ]
    }
  },
  {
    owner: {
      id: 'Aminata',
      name: 'Aminata',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'Aminata1',
          name: 'Marcory Centre. Studio Meublé.',
          description: 'Logement affichant un style résolument unique, élégant et paisible. Idéalement situé à 5 minutes à pied du Grand Marché de Marcory, en face de la pharmacie Maradiana; proche de la Cité 3K et des lignes de Bus 03; 05; 11; 56. Stationnement gratuit aux alentours. Plein pieds et neuf. Cuisine équipée (Frigo, Micro-onde, Bouilloire, cuisinière, vaisselle.) TV connectée 55 pouces, Wifi, Canal+. Canapé convertible en lit double 180/200 cm; Placard, les serviettes et les draps sont fournis.',
          ownerId: 'Aminata',
          area: 'Idéalement situé à 5 minutes à pieds du Grand Marché de Marcory, en face de la Pharmacie Maradiana; proche de la Cité 3K.',
          transports: 'Lignes de Bus 03; 05; 11; 56.',
          rooms: 1,
          beds: 1,
          capacity: 2,
          wifi: true,
          coffee: false,
          type: 'Hébergement',
          city: 'Abidjan',
          price: 'Pas mentionné',
          mainFilename: 'f25baf50-c5d9-40f0-a7f1-3dde03e9e5f9.webp',
          otherFilenames: [
            '087c774b-cb9f-4568-b20e-59f96ed8fb98.webp',
            '13ad7a05-86b1-46e0-9836-a5417c1c177d.webp',
            '5707a8e4-805d-464b-be26-90bfd9f06ca8.webp',
            '73b28a15-9832-406e-bcd0-7bb20df9c74d.webp',
            '940dae1c-a8c5-4def-bb2d-c2536ed9fe38.webp',
            'c04e5bd7-ba0d-429a-bf27-f0a47d9b7fe5.webp',
            'dc1f4221-f67b-49d2-8080-fd9a3a215f32.webp',
            'e569d1e1-4c8f-436f-84b1-b7189aa383de.webp'
          ]
        },
        {
          id: 'Aminata2',
          name: 'Marcory Centre. Studio Cosy Chic meublé.',
          description: 'Besoin de se détendre? Logement calme et élégant est idéalement situé à 5 minutes à pied du Grand Marché de Marcory, en face de la pharmacie Maradiana; proche de la Cité 3K et des lignes de Bus 03; 05; 11; 56. Stationnement gratuit aux alentours. Logement Plain pieds neuf. Cuisine équipée (Frigo, Micro-onde, Bouilloire, plaque électrique, vaisselle.) TV connectée 55 pouces, Wifi, Canal+. Canapé convertible en lit double 180/200 cm; Placard, les serviettes et les draps sont fournis.',
          ownerId: 'Aminata',
          area: 'Situé à 5 minutes à pied du Grand Marché de Marcory, en face de la pharmacie Maradiana. Stationnement gratuit aux alentours.',
          transports: 'Lignes de Bus 03; 05; 11; 56.',
          rooms: 1,
          beds: 1,
          capacity: 2,
          wifi: true,
          coffee: false,
          type: 'Hébergement',
          city: 'Abidjan',
          price: 'Pas mentionné',
          mainFilename: '8afba805-0ba8-4f3d-9aca-af9152e6a2da.webp',
          otherFilenames: [
            '087c774b-cb9f-4568-b20e-59f96ed8fb98.webp',
            '13ad7a05-86b1-46e0-9836-a5417c1c177d.webp',
            '5707a8e4-805d-464b-be26-90bfd9f06ca8.webp',
            '73b28a15-9832-406e-bcd0-7bb20df9c74d.webp',
            '940dae1c-a8c5-4def-bb2d-c2536ed9fe38.webp',
            'c04e5bd7-ba0d-429a-bf27-f0a47d9b7fe5.webp',
            'dc1f4221-f67b-49d2-8080-fd9a3a215f32.webp',
            'e569d1e1-4c8f-436f-84b1-b7189aa383de.webp'
          ]
        },
        {
          id: 'Aminata3',
          name: 'Le Havre du Bonheur',
          description: 'Bienvenu au Havre du bonheur! Studio calme, et tout confort, situé à Saint-Denis; proche de Paris. Logement situé au 2ème étage avec ascenseur. Plusieurs points accessibles à pied en moins de 15mn. Stade de France, Hôpital de la Fontaine; Bureau de poste Henri Barbusse; Métro Saint Denis Basilique; Métro Saint Denis université; Centre Ville; Supermarché Carrefour Basilique; Piscine municipale la baleine. Un lit 2 places; un canapé convertible pour 2 personnes. Draps et serviettes fournis. Le bel Havre du bonheur est un logement doté d’une décoration d’intérieur épurée avec beaucoup de charme. Vous y trouverez calme et sérénité. Un lieu agréable et tout confort avec une connexion wifi, une télévision écran plat proposant les chaînes du monde et Netflix et enfin, des boissons chaudes en produits d’accueil pour bien démarrer votre séjour (café, thé, chocolat). La résidence est entièrement sécurisée.',
          ownerId: 'Aminata',
          area: 'Accès à l’intégralité du logement; très accessible et adapté aux handicapés. Passage fauteuil roulant depuis l\'entrée principale, jusqu\'à l\'appartement en passant plusieurs portes sécurisées et ascenseur.',
          transports: 'situé à Saint-Denis; proche de Paris.',
          rooms: 1,
          beds: 1,
          capacity: 4,
          wifi: true,
          parking: false,
          coffee: true,
          type: 'Appartement',
          city: 'Saint-Denis, France',
          price: '',
          mainFilename: '18bf1faa-95f6-4b71-8a8d-c9ff93ea446c.webp',
          otherFilenames: [
            '1293a706-7eb8-4380-8db5-a46b57d066be.webp',
            '2883b4f9-e2b9-47db-8de7-1a1e4d9b3025.webp',
            '307a1cf3-7b58-4698-9e0c-55f026ef31be.webp',
            '45c79aa7-445d-487c-8b0a-c39b1a6e94b0-1.webp',
            '45c79aa7-445d-487c-8b0a-c39b1a6e94b0.webp',
            'c3594649-ecb6-49a1-ad12-732ee77d04dc.webp',
            'd8804c17-c90f-464a-8627-e86527bc7f4f.webp',
            'e06e02bc-f2b3-4309-9a1b-ec659832646e.webp',
            'f4843bb4-9c55-4bca-95e0-71b38bbbc093.webp'
          ],
          interest: 'Stade de France, Hôpital de la Fontaine; Bureau de poste Henri Barbusse; Métro Saint Denis Basilique; Métro Saint Denis université; Centre Ville; Supermarché Carrefour Basilique; Piscine municipale la baleine.'
        },
      ]
    }
  },
  {
    owner: {
      id: 'Caroline',
      name: 'Caroline',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'Caroline1',
          name: 'Le doux refuge proche Paris et Disneyland',
          description: 'Notre appartement est situé à 300 mètre du centre ville, la station parc de Saint Maur sur le RER A est à 1,5km, le bus 112 à 150 mètres de l\'entrée du bâtiment. A quelques pas vous avez également toutes les commodités (Auchan, carrefour market, restaurants, boulangeries, tabac, pharmacies,...) Idéal pour les déplacements professionnels ou escapade en amoureux. Profitez des bords de Marne pour courir ou vous promener. A disposition, un lit parapluie pour les jeunes parents',
          ownerId: 'Caroline',
          location: 'Le havre, France',
          transports: 'situé à 300 mètre du centre ville, la station parc de Saint Maur sur le RER A est à 1,5km, le bus 112 à 150 mètres de l\'entrée du bâtiment.',
          interest: 'A quelques pas vous avez également toutes les commodités (Auchan, carrefour market, restaurants, boulangeries, tabac, pharmacies,...)',
          rooms: 1,
          beds: 1,
          capacity: 2,
          wifi: true,
          coffeeMachine: true,
          type: 'Appartement',
          price: 'Pas mentionné',
          mainFilename: '8bdd830e-3603-496c-814c-a40dbd50ed0f.webp',
          otherFilenames: [
            '2559fccf-13d4-4a52-a681-98a2636310cf.webp',
            '2b0f8144-ebd1-454f-a21a-cd48f2770de4.webp',
            '330d0503-2bd5-456b-bde6-f54d316aa39f.webp',
            '43b70ecb-05e6-49af-93e9-2fcda0105ba5.webp',
            '64a2e937-bc38-4e44-989f-e8673449dbd0.webp',
            '9a7c4899-936a-441a-bfaa-7e7bdc5d0efb.webp',
            'cbcbc23e-4e94-4a10-a430-1e90c0ce88f4.webp',
            'dd5d1cd4-1b85-48c7-9cd9-c7ddd85b60b5.webp'
          ]
        },
        {
          id: 'Caroline2',
          name: 'Le refuge coloré très proche de Paris',
          description: 'Ce charmant appartement est situé à 900 mètres du métro 5 station Pablo Picasso, donnant un accès direct à Paris en 5 min pour visiter les lieux mythiques: la Tour Eiffel, Notre Dame, les Champs Elysées,... En voiture, ce logement est à 14 minutes du Stade de France et du village olympique, 25 minutes de l’aéroport CDG. Il est au rez-de-chaussée d\'une résidence de 2 étages, proche de toutes commodités. Vous bénéficiez d\'équipements électroménager et télé sur place ainsi qu\'une connexion Wifi. Le logement fait 28m2 au total avec une chambre de 16m2 et un séjour de 18m2. La salle d\'eau et les WC sont séparés. Vous avez dans la chambre un grand lit de 160X200 et un coin bureau pour travailler en toute tranquillité. La cuisine ouverte sur le séjour, vous permet de manger tout en regardant la télé. Vous avez également accès à la connexion Wifi dans tout l\'appartement. Le linge de lit ainsi que le café, papier toilette, savon et shampoing sont mis à disposition pendant votre séjour. L\'accès au logement se fait de manière autonome.',
          ownerId: 'Caroline',
          area: 'Vous avez accès à la totalité du logement',
          transports: 'situé à 900 mètres du métro 5 station Pablo Picasso',
          rooms: 1,
          beds: 2,
          capacity: 4,
          wifi: true,
          parking: false,
          coffee: true,
          type: 'Appartement',
          city: 'Bobigny, France',
          price: '',
          mainFilename: 'b2c85202-5131-4f44-8893-beb4ab05704a.webp',
          otherFilenames: [
            '2230722e-8f25-4cd0-a3b1-70828976451e.webp',
            '224cac78-a5ec-478b-acd6-c1329d23f1cf.webp',
            '72dc2f28-87ad-4114-b299-22318df8f385.webp',
            '784fe580-3792-4c0d-a162-6b2d8b1f11dd.webp',
            '7c3fd092-97ef-47b2-b4a0-5b42320556b6.webp',
            '8768d4dd-9ea8-41b8-8427-46596dadde66.webp',
            'cceae82b-e72e-4c4e-9e77-ef8af11afebf.webp',
            'e06e02bc-f2b3-4309-9a1b-ec659832646e.webp',
            'f8e52924-5092-4621-8596-26a0957bfc41.webp'
          ],
          interest: 'Restaurant La Mascotte de l\'Europe à 150 m, Restaurant Le Bar Restaurant le 63 à 200 m, Restaurant Dc Paris à 250 m, Centre Pompidou à 8 km, Sainte-Chapelle à 9 km, Musée du Louvre à 9 km, Jardin des Tuileries à 9 km, Jardin du Luxembourg à 9 km, Musée d\'Orsay à 10 km, Musée de l\'Orangerie à 10 km, Musée Rodin à 10 km, Arc de Triomphe à 11 km, Tour Eiffel à 12 km'
        }
      ]
    }
  },
  {
    owner: {
      id: 'Fabricio',
      name: 'Fabricio',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'Fabricio1',
          name: 'F2 - RDC - L\'écume des vagues',
          description: 'Ce logement paisible offre un séjour détente pour toute la famille. Vous profiterez d\'un logement de plain pied joliment décoré',
          ownerId: 'Fabricio',
          area: 'Le havre, France',
          transports: 'Arrêt de bus 4 à proximité',
          rooms: 1,
          beds: 1,
          capacity: 4,
          wifi: true,
          parking: false,
          coffee: true,
          type: 'Appartement',
          city: 'Le havre, France',
          price: 'Pas mentionné',
          mainFilename: '19ac477a-3f65-4142-9f8d-ce38b74428b9.webp',
          otherFilenames: [
            '15866c54-7e20-4252-a293-69c2fd73c257.webp',
            '461ac44f-51e0-4914-8db4-a09932ffd043.webp',
            '511eb79f-7a05-4f9f-b475-11bd69efc965.webp',
            '969f2c5f-88dc-4e21-bd09-ae8fb75c869c.webp',
            'e5d7b315-c07b-4583-ba33-4fcff8678aa5.webp'
          ],
          interest: '15min à pied de la plage et 20min du centre-ville, Accès à la plage'
        }
      ]
    }
  },
  {
    owner: {
      id: 'Pierre',
      name: 'Pierre',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: `${process.env.PUBLIC_URL}/profile-pic.jpeg`,
      rentals: [
        {
          id: 'Pierre1',
          name: 'Mas des fleurs à Saint Rémy de Provence',
          description: 'Mas provençal du XVIIIe avec un parc d\'1 hectare à 5 mn du centre ville et au pieds des Alpilles, grand parking privé. Composition: cuisine ouverte équipée, salle à manger, bureau, salon, bibliothèque, chambre avec jacuzzi, wc et douche, 5 autres chambres avec salle de bain dont 1 dortoir avec 1 lit double, 2 lits simples, buanderie. A l\'extérieur, piscine 16m*5m, pool house avec cuisine extérieure et terrasses. En juillet et août, les locations se font du samedi au samedi, à la semaine.',
          ownerId: 'Pierre',
          area: 'Saint-Rémy-de-Provence, France',
          transports: 'Mas provençal du XVIIIe avec un parc d\'1 hectare, une piscine, à 5 mn du centre-ville, idéalement situé sur la commune de Saint Rémy de Provence, aux pieds des Alpilles, avec un parking privé pour plusieurs voitures.',
          rooms: 7,
          beds: 9,
          capacity: 15,
          wifi: true,
          parking: true,
          coffee: true,
          type: 'Hébergement',
          city: 'Saint-Rémy-de-Provence, France',
          price: 'Pas mentionné',
          mainFilename: '32babf4a-c836-4f21-8d8e-05bec5d2d39b.webp',
          otherFilenames: [
            '049f9d36-16ba-46fe-9f79-10c8f6ccb1ff.webp',
            '21e4bba1-648c-457f-b390-53527fbaee6c.webp',
            '22a003f5-91a0-4eff-b468-137b44dd5ced.webp',
            '5cce85fa-a620-412f-a31c-3729c9893026.webp',
            '6c04979b-6217-40e7-b254-e55ef2a18303.webp',
            '6c098a6d-4d89-4be5-90c3-4b70810dd599.webp',
            '6eb35196-db40-45c0-8d65-6c323f6a4549.webp',
            '6ecc37f6-345a-4e40-82aa-c198527b5042.webp',
            '7a6970b7-9451-45b6-bcaf-cf8d650bc92a.webp',
            '7f970383-ce93-41f3-b9da-1b7f9f22aec8.webp',
            '8d8b0bd6-9f71-46c3-8f33-58dd5a000b82.webp',
            'b2f312a8-7343-4b36-8ea3-c9940850a47b.webp',
            'bd7bf858-5fdf-4abc-bffc-6cc4093dce4a.webp',
            'c74ee79f-f338-40e9-a876-10f7e855a0f8.webp',
            'd995d2aa-1dab-4ec8-9e4e-ccf156d71a39.webp',
            'e0d5ac46-7dd8-4ad8-8924-7f555e1021e1.webp',
            'f9403ad1-783b-4e26-87fb-99164c2b4d8f.webp'
          ],
          interest: 'Situé à proximité de St Remy et accès facile aux Alpilles directement depuis la maison.'
        },
        {
          id: 'Pierre2',
          name: 'Villa Mahatsinjo à Antsiranana',
          description: 'Villa de vacances idéale pour une famille ou un groupe d’amis. La villa est composée d’un grand séjour de 70 m2 salon avec TV, canal satellite, une salle à manger pour 15 personnes, une cuisine américaine équipée de 45 m2, 3 chambres double dont 1 suite parentale avec baignoire d’angle et douche italienne. Le salon est ouvert sur une terrasse qui borde la piscine. Le quartier calme et sécurisé, à 10mn du centre-ville et à 8mn de l’aéroport. Une piscine de 14m fera la joie de tout le monde.',
          ownerId: 'Pierre',
          area: 'Antsiranana, Madagascar',
          transports: 'La villa est située dans un quartier calme et sécurisé, à 10mn du centre-ville et à 8mn de l’aéroport.',
          rooms: 4,
          beds: 7,
          capacity: 7,
          wifi: true,
          parking: true,
          coffee: true,
          type: 'Villa',
          city: 'Antsiranana',
          price: 'Pas mentionné',
          mainFilename: 'f504bb62-c15a-44b6-8d8a-08a1fc45d703.webp',
          otherFilenames: [
            '1747a239-9115-429f-ae24-a3b6dc9ded7a.webp',
            '19ace5d5-e0fb-46e3-8ed3-6f857f760167.webp',
            '2514b0ec-f616-47dc-a873-9ae115c5f966.webp',
            '272917b2-40a5-44c1-b11e-578980f51000.webp',
            '2a752406-2a47-41c9-bfe1-e407fa0fc758.webp',
            '4c415429-ce12-4824-879f-0dcfb19011cb.webp',
            'dcab3af0-25ad-46f4-94bb-6a10766b6198.webp',
            'e12205fa-d04d-464e-91d4-9d34f2980572.webp',
            'ed202ab4-dc76-408c-ae98-7f5b4c78542e.webp'
          ],
          interest: ''
        }
      ]
    }
  },
  {
    owner: {
      id: 'Heynyss',
      name: 'Heynyss',
      description: '',
      email: 'diane.d@estherboua-consulting.com',
      phoneNumber: '',
      photo: '${process.env.PUBLIC_URL}/profile-pic.jpeg',
      rentals: [
        {
          id: 'Heynyss1',
          name: 'The Heynyss\'Grace - Appartement à 15 min de Paris',
          description: 'Situé dans une résidence calme, à 15min en voiture de l\'aéroport de Paris-Orly. La Gare de Savigny-sur-Orge à 5 min à pied, avec le RER C vous emmène en 15 min aux portes de PARIS. Les draps et serviettes sont fournis. Accès au logement en arrivée autonome. Un box pour garer votre voiture. Nous mettons à disposition le nécessaire pour votre jour d’arrivée (sel, poivre, sucre, huile, papier toilette, dosettes de café et thé) que vous pouvez compléter selon vos besoins pendant votre séjour.',
          ownerId: 'Heynyss',
          area: 'La Gare de Savigny-sur-Orge à 5 min à pied, à 15min en voiture de l\'aéroport de Paris-Orly.',
          transports: 'La Gare de Savigny-sur-Orge à 5 min à pied, à 15min en voiture de l\'aéroport de Paris-Orly.',
          rooms: 2,
          beds: 1,
          capacity: 4,
          wifi: true,
          parking: false,
          coffee: true,
          type: 'Appartement',
          city: 'Savigny-sur-Orge, France',
          price: '',
          mainFilename: '4eb1d960-3570-4bb7-82cf-6a9b21bb54aa.webp',
          otherFilenames: [
            '08d295fb-be38-4f83-938a-07fae5937b23.webp',
            '2f1513f0-f402-4dc3-a9be-70708d7d369a.jpeg',
            '2f1513f0-f402-4dc3-a9be-70708d7d369a.webp',
            '2f3c30b0-d567-4f72-bf48-fa788d071f1b (1).jpeg',
            '8634b71a-bd35-46b5-9dfd-1c298fd4bd95.webp',
            '9bd2b4bd-5d0d-47b4-a380-e10330f060ce.webp',
            'aaebded2-41ae-4b8d-bf63-8eecfc084a04.webp',
            'c22f7b85-4eb8-4756-87e8-0bc3844e2be1.webp',
            'fe7d9d0c-8087-430f-8bb1-dd76ce1fbe38.webp'
          ],
          interest: 'le RER C vous emmène en 15 min aux portes de PARIS.'
        }
      ]
    }
  },
  {
    owner: {
      id: 'EstherBoua',
      name: 'Esther Boua',
      description: '',
      email: 'kalouesther@yahoo.fr',
      phoneNumber: '+33 6 25 60 15 94',
      photo: '${process.env.PUBLIC_URL}/profile-pic.jpeg',
      rentals: [
        {
          id: 'EstherBoua1',
          name: 'Cobalt Bohème',
          description: 'Bienvenue au Cobalt Bohème! Un studio de 18 m2 décoré avec goût. Situé à Villetaneuse, une ville calme et verdoyante à proximité de Saint-Denis et de Paris. Au sein d\'une résidence arborée, le Cobalt Bohème est un logement idéal pour un séjour professionnel. Vous aurez accès à un espace extérieur aménagé! Le tramway (T5) est à 10 min à pied et vous emmène en 15 min au Marché de Saint Denis. La Gare de Villetaneuse Université est à 10 min à Pied. Dès que vous franchirez la porte, vous serez séduit par l\'atmosphère du lieu! L\'appartement est pour 2 personnes et se situe à proximité des commerces et des transports en commun pour un accès facile à Paris. Vous aurez à disposition: - Une cuisine aménagée et équipée avec : réfrigérateur, four, micro-ondes, plaques de cuisson vitrocéramiques, cafetière et, bouilloire. - Un séjour + salle à manger avec: téléviseur HD, table à manger et chaises. - 1 couchage: canapé convertible 140x190cm - Une salle d\'eau/WC avec: douche, toilette, un Sèche-cheveux est également à votre disposition. Les draps et serviettes sont fournis dans l\'appartement. Le stationnement est gratuit dans la rue.',
          ownerId: 'EstherBoua',
          area: 'Quartier pavillonnaire très calme, à proximité des transports et des commerces. stationnement gratuit dans les rues adjacentes Bus 156 - arrêt: collège Lucie Aubrac Tramway 5 - bus 156 arrêt : Suzanne Valadon Gare RER D Pierrefitte Stains',
          transports: 'Quartier pavillonnaire très calme, à proximité des transports et des commerces. stationnement gratuit dans les rues adjacentes Bus 156 - arrêt: collège Lucie Aubrac Tramway 5 - bus 156 arrêt : Suzanne Valadon Gare RER D Pierrefitte Stains',
          rooms: 2,
          beds: 1,
          capacity: 4,
          wifi: true,
          parking: false,
          coffee: true,
          type: 'Appartement',
          city: 'Villetaneuse',
          price: '45 EUR',
          mainFilename: 'COBALT_BOHEME_PHOTO_PRINCIPALE.jpg',
          otherFilenames: [
            'COBALT_BOHEME_2.jpg',
            'COBALT_BOHEME_3.jpg',
            'COBALT_BOHEME_4.jpg',
            'COBALT_BOHEME_5.jpg',
            'COBALT_BOHEME_6.jpg'
          ],
          interest: 'Situé à Villetaneuse, une ville calme et verdoyante à proximité de Saint-Denis et de Paris. Vous profiterez ensemble d\'un espace extérieur aménagé, de commerces à proximité et des transports pour vous déplacer à Paris'
        },
        {
          id: 'EstherBoua2',
          name: 'Le Kaki Bohème',
          description: 'Offrant une vue sur son jardin, Le charmant Studio Kaki Bohème propose un hébergement avec un jardin et une terrasse, à environ 6,4 km du stade de France. Cet appartement se trouve à 12 km de la station de métro Pigalle et du Sacré-Cœur. L\'appartement comprend une cuisine équipée d\'un micro-ondes, d\'un réfrigérateur et d\'une machine à café. Les serviettes et le linge de lit sont fournis. Vous séjournerez à 12 km de la salle de concert La Cigale et de la gare du Nord. L\'aéroport de Paris-Charles-de-Gaulle, le plus proche, est implanté à 17 km.',
          ownerId: 'EstherBoua',
          area: 'Quartier pavillonnaire très calme, à proximité des transports et des commerces. stationnement gratuit dans les rues adjacentes se déplacer: Bus 256 - arrêt: collège Lucie Aubrac - Tramway 5 - bus 256 arrêt : Suzanne Valadon Gare RER D Pierrefitte Stains - Tramway 1 - Marché de saint Denis - Metro 13 - Basilique de Saint Denis. Le tramway (T5) est à 10 min à pied et vous emmène en 15 min au Marché de Saint Denis. La Gare de Villetaneuse Université est à 10 min à Pied',
          transports: 'Quartier pavillonnaire très calme, à proximité des transports et des commerces. stationnement gratuit dans les rues adjacentes se déplacer: Bus 256 - arrêt: collège Lucie Aubrac - Tramway 5 - bus 256 arrêt : Suzanne Valadon Gare RER D Pierrefitte Stains - Tramway 1 - Marché de saint Denis - Metro 13 - Basilique de Saint Denis. Le tramway (T5) est à 10 min à pied et vous emmène en 15 min au Marché de Saint Denis. La Gare de Villetaneuse Université est à 10 min à Pied',
          rooms: 2,
          beds: 1,
          capacity: 4,
          wifi: true,
          parking: false,
          coffee: true,
          type: 'Appartement',
          city: 'Pontoise',
          price: '45 EUR',
          mainFilename: 'KAKI_BOHEME_PHOTO_PRINCIPALE.jpg',
          otherFilenames: [
            'KAKI_BOHEME_2.jpg',
            'KAKI_BOHEME_3.jpg'
          ],
          interest: 'Situé à Villetaneuse, une ville calme et verdoyante à proximité de Saint-Denis et de Paris. Vous profiterez ensemble d\'un espace extérieur aménagé, de commerces à proximité et des transports pour vous déplacer à Paris'
        },
        {
          id: 'EstherBoua3',
          name: 'Le bleu Azur',
          description: 'Bienvenue au Bleu Azur! Nous vous proposons un logement T3 entièrement rénové avec goût à Argenteuil proche de Paris, de l\'Aéroport CDG et du Stade France , l\'emplacement est idéal pour vous rendre à tous vos rendez-vous professionnels et explorer les sites emblématiques de la capitale en un rien de temps. La résidence se situe dans un quartier résidentiel pavillonnaire. L\'appartement est lumineux avec de grandes fenêtres qui donnent vue sur un jardin paisible. Dès que vous franchirez la porte , vous serez séduit par l\'atmosphère du lieu. L\'appartement  se situe à proximité de la mairie, des commerces et des transports en commun pour un accès facile à Paris. Vous aurez à disposition: - 1 cuisine aménagée et équipée avec : réfrigérateur, four, micro-ondes, plaques de cuisson vitrocéramiques, cafetière Senseo et, bouilloire. - 1 séjour + salle à manger avec: canapé 2 places, téléviseur HD, table à manger et chaises. - 2 chambre à coucher avec: lit 2 places confortable 140*190 , armoire, - 1 salle d\'eau/WC avec: douche, toilette, un Sèche-cheveux et un lisseur sont également à votre disposition. Les draps et serviettes sont fournis dans l\'appartement. Le logement bénéficie de la WIFI, parfait pour votre télétravail Le stationnement se fait gratuitement dans les rues adjacentes Pour le stationnement, des places gratuites et payantes ainsi que des parkings sont disponibles dans les rues adjacentes.',
          ownerId: 'EstherBoua',
          area: 'Le logement se prête à vos séjours d’affaires,  Vous trouverez toutes les commodités dans un rayon de 150m (supermarché, pharmacie, cinéma, restaurant, centre commercial...).',
          transports: 'Les transports en commun à proximité vous permettront de rejoindre facilement le centre de Paris et de découvrir ses attractions emblématiques comme les Champs Elysées, la tour Eiffel et autres en 30 à 45 min',
          rooms: 2,
          beds: 1,
          capacity: 4,
          wifi: true,
          parking: false,
          coffee: true,
          type: 'Appartement',
          city: 'Argenteuil',
          price: '90 EUR',
          mainFilename: 'vibrant_azur_photo_principale.jpeg',
          otherFilenames: [
            'VIBRANT_AZUR_2.jpeg',
            'VIBRANT_AZUR_3.webp',
            'VIBRANT_AZUR_4.webp',
            'VIBRANT_AZUR_5.webp',
            'VIBRANT_AZUR_6.jpeg',
            'VIBRANT_AZUR_7.jpeg',
            'VIBRANT_AZUR_8.webp'
          ],
          interest: 'Situé à Villetaneuse, une ville calme et verdoyante à proximité de Saint-Denis et de Paris. Vous profiterez ensemble d\'un espace extérieur aménagé, de commerces à proximité et des transports pour vous déplacer à Paris'
        },
        {
          id: 'EstherBoua4',
          name: 'Le Bright Modern',
          description: 'Le Bright Modern - studio proche Paris est situé à Pontoise, à 29 km du stade de France, à 31 km de l\'Arc de Triomphe et de la station de métro Pigalle. Il se trouve à 21 km du parcours de golf de Saint-Germain et dispose d\'une réception ouverte 24h/24. L\'appartement comprend une télévision. La cuisine est équipée d\'un lave-vaisselle, d\'un four, d\'un micro-ondes, d\'une machine à café et d\'une bouilloire. Vous séjournerez à 32 km du Sacré-Cœur et de la gare Saint-Lazare. L\'aéroport de Paris-Charles-de-Gaulle, le plus proche, est implanté à 40 km.',
          ownerId: 'EstherBoua',
          area: 'Le quartier Centre-ville Pontoise, proche de toutes commodités',
          transports: 'Transports Gare sncf Pontoise',
          rooms: 2,
          beds: 1,
          capacity: 4,
          wifi: true,
          parking: false,
          coffee: true,
          type: 'Appartement',
          city: 'Pontoise',
          price: '45 EUR',
          mainFilename: 'brignt_modern_photo_principale.jpg',
          otherFilenames: [
            'BRIGHT_MODERN_10.jpg',
            'BRIGHT_MODERN_3.jpg',
            'BRIGHT_MODERN_4.jpg',
            'BRIGHT_MODERN_5.jpg',
            'BRIGHT_MODERN_6.jpg',
            'BRIGHT_MODERN_7.jpg',
            'BRIGHT_MODERN_8.jpg',
            'BRIGHT_MODERN_9.jpg',
            'bright_modern_2.jpg'
          ],
          interest: 'Points d’intérêt dans le quartier : Le quartier Centre-ville Pontoise, proche de toutes commodités'
        },
        {
          id: 'EstherBoua5',
          name: 'L\'élégant chic Paris',
          description: 'L\'Elégant Chic est un appartement cosy de 46m2 idéal pour vos déplacements professionnels. Situé à Noisy-le-Grand, à 18 km de l\'opéra Bastille, à 19 km de la gare du Nord et de la gare de l\'Est. Vous séjournerez à 17 km de Paris-Gare-de-Lyon et bénéficierez d\'une connexion Wi-Fi gratuite dans l\'ensemble des locaux. Cet appartement d\'une chambre comprend un salon avec une télévision, une cuisine entièrement équipée avec un lave-vaisselle et un four, ainsi qu\'une salle de bains avec un sèche-cheveux. Les serviettes et le linge de lit sont fournis. Vous séjournerez à 19 km de la cathédrale Notre-Dame et à 20 km de la Sainte-Chapelle. L\'aéroport de Paris-Orly, le plus proche, est implanté à 23 km.',
          ownerId: 'EstherBoua',
          area: 'Appartement situé sur le RER A, proche de Paris, Val d\'Europe, La Vallée village (pour votre shopping). Quartier calme au centre-ville, proche des transports en commun, des restaurants et des commerces ( boulangerie, Franprix, coiffeur, Tabac, etc.) Stationnement public en sous-sol (payant) et parking extérieur dans la rue',
          transports: 'Bus au pied du batiment, 5 mn de la Gare Sncf RER A, Autoroute A4 Autoroute A86',
          rooms: 2,
          beds: 1,
          capacity: 4,
          wifi: true,
          parking: false,
          coffee: true,
          type: 'Appartement',
          city: 'Noisy le Grand',
          price: '60 EUR',
          mainFilename: 'PHOTO_PRINCIPALE_LELEGANT_CHIC.jpg',
          otherFilenames: [
            'LELEGANT_CHIC_10.jpg',
            'LELEGANT_CHIC_2.jpg',
            'LELEGANT_CHIC_3.jpg',
            'LELEGANT_CHIC_4.jpg',
            'LELEGANT_CHIC_5.jpg',
            'LELEGANT_CHIC_6.jpg',
            'LELEGANT_CHIC_7.jpg',
            'LELEGANT_CHIC_8.jpg',
            'LELEGANT_CHIC_9.jpg'
          ],
          interest: 'Points d’intérêt dans le quartier : Appartement situé sur le RER A, proche de Paris, Val d\'Europe, La Vallée village (pour votre shopping). Quartier calme au centre-ville, proche des transports en commun, des restaurants et des commerces (boulangerie, Franprix, coiffeur, Tabac, etc.) Stationnement public en sous-sol (payant) et parking extérieur dans la rue'
        },
      ]
    }
  }
];

function updateRentalImages(data) {
  // Updated functions to include ownerId and rentalNumber
  const getMainImageURLForClient = (ownerId, rentalNumber, mainFilename) => 
    `${awsRentalsURL}/${ownerId}/${rentalNumber}/${mainFilename}`;

  const getOtherImageURLsForClient = (ownerId, rentalNumber, otherFilenames) => 
    otherFilenames.map(filename => `${awsRentalsURL}/${ownerId}/${rentalNumber}/${filename}`);

  return data.map(ownerObj => {
    ownerObj.owner.rentals.forEach((rental, index) => {
      // Calculate rentalNumber as index + 1
      const rentalNumber = index + 1;
      rental.imageUrl = getMainImageURLForClient(ownerObj.owner.id, rentalNumber, rental.mainFilename);
      rental.otherImagesUrl = getOtherImageURLsForClient(ownerObj.owner.id, rentalNumber, rental.otherFilenames);
    });
    return ownerObj;
  });
}

const Data = updateRentalImages(tmpData);

export default Data;
