/* eslint-disable max-len, no-unused-vars, no-nested-ternary */

import React, { useState } from 'react';
import { InputBase, Paper, Grid, Typography, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const SearchBar = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  width: '80%',
  maxWidth: 700,
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  flex: 1,
  marginLeft: theme.spacing(1),
}));

const highlightedPoints = [
  {
    title: 'Confort des Professionnels',
    description: 'Des espaces pensés pour votre bien-être professionnel',
  },
  {
    title: 'Flexibilité de Location',
    description:
      'Des offres adaptées à vos besoins temporaires ou à long terme',
  },
  {
    title: 'Localisation Stratégique',
    description: "Situez-vous au cœur des opportunités d'affaires",
  },
];

function Home() {
  // Use these constants to conditionally render or apply styles based on the screen size.
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.between('sm', 'md')
  );

  const styles = {
    homeSection: {
      backgroundImage:
        "url('https://probnb-public.s3.eu-west-3.amazonaws.com/public-assets/home.png')",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '70vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    overlay: {
      position: 'absolute',
      bottom: 0,
      backgroundColor: 'rgba(56, 77, 102, 0.4)',
      color: 'white',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 0',
    },
    hiddenTitle: {
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0,
      height: 0,
      width: 0,
    },
    point: {
      textAlign: 'center',
    },
    pointTitle: {
      fontSize: isSmallScreen ? '12px' : isMediumScreen ? '14px' : '18px',
      fontWeight: 'bold',
    },
    pointDescr: {
      fontSize: isSmallScreen ? '10px' : isMediumScreen ? '12px' : '16px',
    },
    searchIcon: {
      color: '#384D66',
    },
  };

  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const handleSearch = () => {
    navigate('/rentals/' + search);
  };

  return (
    <section id='home' style={styles.homeSection}>
      <h1 style={styles.hiddenTitle}>Accueil</h1>
      <SearchBar elevation={10}>
        <form
          onSubmit={handleSearch}
          style={{ display: 'flex', width: '100%' }}
        >
          <SearchInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder='Entrez une ville ou une adresse'
            inputProps={{ 'aria-label': 'search' }}
          />
          <IconButton type='submit' sx={{ p: '10px' }} aria-label='search'>
            <SearchIcon color='primary' style={styles.searchIcon} />
          </IconButton>
        </form>
      </SearchBar>
      <div style={styles.overlay}>
        <Grid container spacing={1}>
          {highlightedPoints.map((point, idx) => (
            <Grid key={idx} item xs={4} style={styles.point}>
              <Typography variant='h6' style={styles.pointTitle}>
                {point.title}
              </Typography>
              <Typography variant='body2' style={styles.pointDescr}>
                {point.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
}

export default Home;
