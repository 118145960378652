/* eslint-disable no-nested-ternary */

import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Container, Grid, Typography, IconButton } from '@mui/material';
import { Email, WhatsApp } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import Logo from './Logo';

function Footer() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.between('sm', 'md')
  );

  const styles = {
    footer: {
      backgroundColor: '#f8f8f8',
      padding: '20px 0',
      color: '#717171',
    },
    link: {
      fontSize: isSmallScreen ? '12px' : isMediumScreen ? '14px' : '18px',
      color: '#384D66',
      textDecoration: 'none',
      '&:hover': {
        color: 'inherit',
        textDecoration: 'none',
      },
    },
    gridColumn: {
      textAlign: 'center',
    },
    text: {
      fontSize: isSmallScreen ? '10px' : isMediumScreen ? '12px' : '16px',
    },
    titles: {
      fontSize: isSmallScreen ? '12px' : isMediumScreen ? '14px' : '18px',
    },
    quickLinks: {
      listStyle: 'none',
      padding: 0,
    },
  };

  return (
    <footer style={styles.footer}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} style={styles.gridColumn}>
            <Link to='/#home'>
              <Logo />
            </Link>
            <Typography
              variant='body2'
              color='textSecondary'
              style={styles.text}
            >
              Votre confort professionnel, notre mission.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} style={styles.gridColumn}>
            <Typography variant='h6' style={styles.titles}>
              Liens Rapides
            </Typography>
            <ul style={styles.quickLinks}>
              <li>
                <Link to='/#home' style={styles.link}>
                  Accueil
                </Link>
              </li>
              <li>
                <Link to='rentals' style={styles.link}>
                  Nos appartements
                </Link>
              </li>
              {/* <li>
                <Link href='#legal' style={styles.link}>
                  Mentions Légales
                </Link>
              </li> */}
            </ul>
          </Grid>
          <Grid item xs={12} md={4} style={styles.gridColumn}>
            <Typography variant='h6' style={styles.titles}>
              Contactez-Nous
            </Typography>
            <IconButton href='mailto:hello@estherboua-consulting.com'>
              <Email />
            </IconButton>
            <IconButton href='tel:0033753966837'>
              <WhatsApp />
            </IconButton>
          </Grid>
        </Grid>
        <Typography
          variant='body2'
          color='textSecondary'
          align='center'
          style={{ marginTop: '20px' }}
        >
          ©{new Date().getFullYear()} probnb. Tous droits réservés.
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;
