import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';

function Logo() {
  const styles = {
    logoIcon: {
      mr: 2,
    },
    logoText: {
      fontFamily: 'Arial, Helvetica, sans-serif',
      fontWeight: 'bold',
      fontSize: '24px',
      color: '#384D66',
      textDecoration: 'none',
      marginRight: 2,
      ml: 1,
      alignSelf: 'center',
      md: {
        display: 'block',
      },
    },
  };

  return (
    <IconButton size='large' color='inherit' sx={styles.logoIcon}>
      <img src={`${process.env.PUBLIC_URL}/logo.png`} alt='logo' />
      <Typography variant='h6' sx={styles.logoText}>
        probnb
      </Typography>
    </IconButton>
  );
}
export default Logo;
