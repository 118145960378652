import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import ReCAPTCHA from 'react-google-recaptcha';

import { styled } from '@mui/material/styles';
import { Button, TextField, Typography, Box } from '@mui/material';

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== 'focusColor',
})((p) => ({
  // input label when focused
  '& label.Mui-focused': {
    color: p.focusColor,
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: p.focusColor,
    },
  },
}));

function Contact() {
  const styles = {
    contactSection: {
      padding: '50px 0',
      backgroundColor: '#CBAF8F',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contactTitle: {
      fontWeight: 'bold',
      color: '#384D66',
      marginTop: '30px',
      marginBottom: '10px',
      textAlign: 'center',
    },
    sendButton: {
      color: 'white',
      backgroundColor: '#384D66',
    },
    textField: {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '5px',
    },
    formBox: {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '5px',
      padding: '20px',
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  };

  const [state, handleSubmit] = useForm('xwkdagez');

  if (state.succeeded) {
    return (
      <section id='contact' style={styles.contactSection}>
        <Typography variant='b1' style={styles.contactTitle}>
          Votre message a été bien envoyé
        </Typography>
      </section>
    );
  }

  return (
    <section id='contact' style={styles.contactSection}>
      <Box maxWidth='sm' style={styles.formBox}>
        <Typography variant='h4' style={styles.contactTitle}>
          Contactez-Nous
        </Typography>
        <form
          noValidate
          autoComplete='off'
          style={styles.form}
          onSubmit={handleSubmit}
        >
          <CssTextField
            style={styles.textField}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='name'
            label='Nom'
            name='name'
            focusColor='#384D66'
          />
          <>
            <CssTextField
              style={styles.textField}
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email'
              name='email'
              type='email'
              focusColor='#384D66'
            />
            <ValidationError
              prefix='Email'
              field='email'
              errors={state.errors}
            />
          </>
          <CssTextField
            style={styles.textField}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='subject'
            label='Sujet'
            name='subject'
            focusColor='#384D66'
          />
          <>
            <CssTextField
              style={styles.textField}
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='message'
              label='Message'
              name='message'
              focusColor='#384D66'
              multiline
              rows={4}
            />
            <ValidationError
              prefix='Message'
              field='message'
              errors={state.errors}
            />
          </>

          <ReCAPTCHA sitekey='6LeZubIoAAAAAE2ao6pln1fYVbIhQbvOCDoMtmZp' />
          <Button
            type='submit'
            variant='contained'
            style={styles.sendButton}
            disabled={state.submitting}
          >
            Envoyer
          </Button>
        </form>
      </Box>
    </section>
  );
}

export default Contact;
