import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  ButtonBase,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import reduceWordsWithEllipsis from '../../utils/reduceWordsWithEllipsis';

const RentalItem = ({ data }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.between('sm', 'md')
  );
  const navigate = useNavigate();
  function handleClick(id) {
    navigate('/rental/' + id);
  }

  return (
    <Card
      sx={{
        marginBottom: 2,
        ':hover': {
          boxShadow: 20,
        },
      }}
      onClick={(e) => handleClick(data.id)}
    >
      <ButtonBase
        style={{
          textAlign: 'left',
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
        }}
      >
        <CardMedia
          component='img'
          sx={{
            margin: isSmallScreen ? '1rem' : '0',
            maxWidth: isSmallScreen ? 250 : isMediumScreen ? 200 : 300,
            height: isSmallScreen ? 250 : isMediumScreen ? 200 : 300,
          }}
          image={data.imageUrl} // Assure you have imageUrl in your data or adjust accordingly
          alt={data.name}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography variant='h5'>{data.name}</Typography>
            <Typography variant='subtitle1' color='text.secondary' gutterBottom>
              {data.area}, {data.city}
            </Typography>
            <Typography variant='h6'>Prix: {data.price}</Typography>
            <Typography variant='body2'>
              {reduceWordsWithEllipsis(data.description, 30)}
            </Typography>
          </CardContent>
        </Box>
      </ButtonBase>
    </Card>
  );
};

export default RentalItem;
