/* eslint-disable max-len, react/prop-types, react/jsx-props-no-spreading, no-unused-vars */ // TODO: upgrade to latest eslint tooling

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Data from '../Data';
import reduceWordsWithEllipsis from '../utils/reduceWordsWithEllipsis';

const RentalCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function FeaturedRentals() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024, // from width >= 1024 to smaller
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900, // from width >= 600 to smaller
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480, // from width >= 480 to smaller
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const styles = {
    title: {
      fontWeight: 'bold',
      color: '#384D66',
      marginTop: '30px',
      marginBottom: '10px',
      textAlign: 'center',
    },
    section: {
      padding: '30px 0',
      textAlign: 'center',
      marginBottom: '30px',
    },
    carouselItem: {
      position: 'relative',
      textAlign: 'center',
    },
    image: {
      width: '100%',
      objectFit: 'cover',
      borderRadius: '10px',
      height: '25vh',
    },
    textZone: {
      position: 'absolute',
      bottom: '20px',
      left: '20px',
      backgroundColor: 'rgba(0,0,0,0.5)',
      color: 'white',
      padding: '10px',
      borderRadius: '5px',
    },
    button: {
      marginTop: '10px',
      borderColor: 'white',
      color: 'white',
    },
    price: {
      marginTop: '10px',
      fontWeight: 'bold',
    },
    seeMoreButton: {
      color: 'white',
      backgroundColor: '#384D66',
    },
  };

  const navigate = useNavigate();
  function handleClick(id) {
    navigate('/rental/' + id);
  }

  // Take randomly 6 rentals from the Data
  function getRandomRentals(numberOfRentals) {
    // Shuffle the array of owners
    const shuffledOwners = Data.sort(() => 0.5 - Math.random());
    // Slice the array to the desired number of rentals
    const selectedOwners = shuffledOwners.slice(0, numberOfRentals);
    // Map through the selected owners and get the first rental from each
    const rentals = selectedOwners.map(owner => owner.owner.rentals[0]);
    return rentals;
  }
  const rentalsData = getRandomRentals(6);

  return (
    <section id='featured-rentals' style={styles.section}>
      <Container fluid>
        <Row>
          {/* <Col xs={12} sm={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}> */}
          <Col xs={12} style={{ width: '85%', margin: 'auto' }}>
            <Typography variant='h4' align='left' style={styles.title}>
              Locations en Vedette
            </Typography>
            <Slider {...settings}>
              {rentalsData.map((rental, idx) => (
                <RentalCard key={idx} sx={{ maxWidth: 345 }}>
                  <CardMedia
                    component='img'
                    alt='FIXME'
                    height='200'
                    image={rental.imageUrl}
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {rental.name}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {reduceWordsWithEllipsis(rental.description, 30)}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      onClick={(e) => handleClick(rental.id)}
                      size='small'
                      style={styles.seeMoreButton}
                    >
                      Voir Plus
                    </Button>
                  </CardActions>
                </RentalCard>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default FeaturedRentals;
