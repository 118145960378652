import React from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';

import ImageGallery from 'react-image-gallery';

function Views({ data }) {
  const styles = {
    viewsSection: {
      width: '90%',
      margin: '0 1rem',
      marginTop: '1rem',
    },
  };

  const { imageUrl, otherImagesUrl } = data;
  const rentalsData = [
    { original: imageUrl, thumbnails: imageUrl },
    ...otherImagesUrl.map((imageUrl) => ({
      original: imageUrl,
      thumbnail: imageUrl,
    })),
  ];

  return (
    <section id='Views' style={styles.viewsSection}>
      <ImageGallery items={rentalsData} />
    </section>
  );
}

export default Views;
