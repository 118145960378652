import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import Filter from './Filter';
import RentalItem from './RentalItem';
import Data from '../../Data';

function getAllRentalsShuffled() {
  let allRentals = [];

  // Extract all rentals into a single array
  Data.forEach(item => {
    allRentals = allRentals.concat(item.owner.rentals);
  });

  // Shuffle the allRentals array in-place using the Fisher-Yates algorithm
  for (let i = allRentals.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
    [allRentals[i], allRentals[j]] = [allRentals[j], allRentals[i]]; // Swap elements
  }

  return allRentals;
}

const RentalList = () => {
  const { search = '' } = useParams();
  const rentals = getAllRentalsShuffled();
  const [searchTerm, setSearchTerm] = useState(search);
  const [filters, setFilters] = useState({
    type: '',
    capacity: '',
    nb_rooms: '',
    nb_beds: '',
    has_wifi: false,
    has_parking: false,
    has_coffee: false,
  });
  const [apartments, setApartments] = useState(rentals);

  const applyFilters = () => {
    let filteredData = [...rentals]; // Start with all apartments

    // Filter by search term
    if (searchTerm) {
      filteredData = filteredData.filter(
        (apartment) =>
          apartment.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          apartment.description
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          apartment.area?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          apartment.transports
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          apartment.city?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Filter by type
    if (filters.type) {
      filteredData = filteredData.filter(
        (apartment) => apartment.type === filters.type
      );
    }

    // Filter by capacity
    if (filters.capacity) {
      filteredData = filteredData.filter(
        (apartment) => apartment.capacity >= filters.capacity
      );
    }

    // Filter by number of rooms
    if (filters.nb_rooms) {
      filteredData = filteredData.filter(
        (apartment) => apartment.rooms >= filters.nb_rooms
      );
    }

    // Filter by number of beds
    if (filters.nb_beds) {
      filteredData = filteredData.filter(
        (apartment) => apartment.beds >= filters.nb_beds
      );
    }

    // Filter by wifi availability
    if (filters.has_wifi) {
      filteredData = filteredData.filter(
        (apartment) => apartment.wifi === filters.has_wifi
      );
    }

    // Filter by parking availability
    if (filters.has_parking) {
      filteredData = filteredData.filter(
        (apartment) => apartment.parking === filters.has_parking
      );
    }

    // Filter by coffee maker availability
    if (filters.has_coffee) {
      filteredData = filteredData.filter(
        (apartment) => apartment.coffee === filters.has_coffee
      );
    }

    // Update the state with the filtered data
    setApartments(filteredData);
  };

  useEffect(() => {
    if (search) applyFilters();
  }, []);

  return (
    <Grid container spacing={0}>
      <Grid item mt={1} xs={12} sm={3} md={2}>
        <Filter
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filters={filters}
          setFilters={setFilters}
          applyFilters={applyFilters}
        />
      </Grid>
      <Grid item mt={3} xs={12} sm={9} md={10}>
        {apartments.map((apartment) => (
          <RentalItem key={apartment.id} data={apartment} />
        ))}
      </Grid>
    </Grid>
  );
};

export default RentalList;
