function reduceWordsWithEllipsis(inputString, maxWords) {
  // Split the input string into words
  const words = inputString.split(' ');

  // Check if the number of words in the input string is less than or equal to the maximum allowed words
  if (words.length <= maxWords) {
    return inputString; // No need to reduce
  }

  // Otherwise, truncate the array of words to the desired length
  const reducedWords = words.slice(0, maxWords);

  // Join the reduced words with spaces and add ellipses
  const reducedString = reducedWords.join(' ') + '...';

  return reducedString;
}

export default reduceWordsWithEllipsis;
