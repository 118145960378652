import React from 'react';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import Views from '../components/Views';
import Description from '../components/Description';
import Persons from '../components/Persons';
import Others from '../components/Others';
import Data from '../Data';

function getRentalAndOwnerById(rentalId) {
  for (const item of Data) {
    const owner = item.owner;
    const rental = owner.rentals.find(rental => rental.id === rentalId);
    if (rental) {
      return {
        rental,
        owner: {
          id: owner.id,
          name: owner.name,
          description: owner.description,
          email: owner.email,
          phoneNumber: owner.phoneNumber,
          photo: owner.photo
        }
      };
    }
  }
  console.error(`No rental and owner found for rental id ${rentalId}`);
  return null; // Return null if no matching rental is found
}

function getOtherRentalsByOwner(ownerId, rentalId) {
  // Find the owner by ownerId
  const ownerData = Data.find(item => item.owner.id === ownerId);

  // If the owner is found, filter out the specified rental from their rentals list
  if (ownerData && ownerData.owner.rentals.length > 1) {
    return ownerData.owner.rentals.filter(rental => rental.id != rentalId);
  }

  // Return an empty list if no other rentals are found or the owner/rental is not found
  return [];
}

function Rental() {
  const { id } = useParams();
  const { rental, owner } = getRentalAndOwnerById(id);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Grid container={isSmallScreen ? false : true}>
        <Grid item xs={12} md={8}>
          <Views data={rental} />
          <Description data={rental} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Persons owner={owner} />
          <Others otherOwnerRentals={getOtherRentalsByOwner(owner.id, id)} ownerId={owner.id} />
        </Grid>
      </Grid>
    </>
  );
}

export default Rental;
